import React from "react";

const SupportingScientificJournalsConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">SUPPORTING SCIENTIFIC JOURNALS</h2>
      <div className="py-2 mt-3 px-3 book-abstracts">
        <p className="text-danger">To be posted soon!</p>
      </div>
    </>
  );
};

export default SupportingScientificJournalsConference1;
