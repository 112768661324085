import React from "react";

const RegistrationForConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">REGISTRATION FOR THE CONFERENCE</h2>
      <div className="py-2 mt-3 px-3 registration">
        <p className="pb-2">
          This is the form for registration to the{" "}
          <b>ICNA-STA Scientific Conference 2023</b>
        </p>
        <p className="pb-2">
          <a
            href="https://docs.google.com/forms/d/15wrIl0EMHmq_uo-CkCnwJF5w1e5YGBy4LoFgSgHPEOQ/edit"
            target="_blank"
          >
            Registration form
          </a>
        </p>
        <p className="pb-2">
          For abstracts with more than one author, only presenting author should
          be registered at the conference, and he/she will get the presentation
          certificate.
        </p>
        <p className="pb-2">
          The colleagues who do not present but like to attend the conference,
          they should be registered as passive participants and will not get any
          certificate.
        </p>
        <p>
          The authors who got confirmation of the abstract acceptance can be
          <b> registered now</b>, while others can be registered the
          <b>latest one week after acceptance of the abstract.</b>
        </p>
      </div>
    </>
  );
};

export default RegistrationForConference1;
