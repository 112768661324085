const UserRole = {
  Admin: 1,
  Author: 2,
  Editor: 3,
  ChiefEditor: 4,
  Reviewer: 5,
  ReviewerBoard: 6,
};

const ArticleStatus = {
  Submitting: 1,
  Submitted: 2,
  InReviewR1: 3,
  ReviewedR1: 4,
  ApprovedR1: 5,
  SubmittedAfterR1: 6,

  InReviewR2: 7,
  ReviewedR2: 8,
  ApprovedR2: 9,
  SubmittedAfterReviewedR2: 10,

  InReviewR3: 11,
  ReviewedR3: 12,
  ApprovedR3: 13,
  SubmittedAfterReviewedR3: 14,

  Rejected: 15,
  Accepted: 16,
  Published: 17,
};

const RequestType = {
  Portal: 1,
  UiSystem: 2,
};

const JournalId = {
  JSCT: "D0D8DD1D-0678-408F-9617-9A6E360B284F",
  PWRI: "C5DD8E5B-12B8-43D7-B30E-63CE3B86029E",
  ICNA_STA: "5D27235D-AAC1-4B2C-8470-77557C20DB0D",
  INT_EAS: "A408E1D9-6C1B-448B-9E54-2A6F5C80F81E",
  SEMINARI: "0A99F0E7-BAB5-447C-A926-21F81E08922B",
  IJML: "E32933C4-B77C-47A1-83BE-D42523EFFFC3",
};

const NewsStatus = {
  Passive: -1,
  SaveDraft: 1,
  Published: 2,
};

const UserRoleType = {
  EditorInChief: 1,
  CoEditorInChief: 2,

  Editor: 3,
  AssociateEditor: 4,
  TechnicalEditor: 5,
  Secretariat: 6,

  Reviewer: 7,
  ReviewersBoard: 8,
};

export {
  UserRole,
  ArticleStatus,
  JournalId,
  RequestType,
  NewsStatus,
  UserRoleType,
};
