import React, { useContext } from "react";
import { JournalContext } from "../store/JournalContext";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { useTranslation } from "react-i18next";

function InstructionsForAuthors() {
  const { journalAuthorInstructions } = useContext(JournalContext);
  const { t } = useTranslation();

  return (
    <div className="card">
      <RouteInactiveChecker isActive={journalAuthorInstructions?.isActive}>
        <h2 className="boardHeader">{t("instructionsForAuthors")}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: journalAuthorInstructions?.value }}
          className="innerDiv"
        ></div>
      </RouteInactiveChecker>
    </div>
  );
}

export default InstructionsForAuthors;
