import React from "react";
import UHZ from "../../../Assets/Images/1-UHZ.png";
import IBC from "../../../Assets/Images/2-IBC_LOGO.jpg";
import SUR from "../../../Assets/Images/3-SUR.jpg";
import UZ from "../../../Assets/Images/4-UZ.jpg";
import UNWE from "../../../Assets/Images/5-UNWE.jpg";
import UP from "../../../Assets/Images/6-up.png";
import UPZ from "../../../Assets/Images/7-upz.png";
import UM from "../../../Assets/Images/8-um.png";
import UGJI from "../../../Assets/Images/9-ugji.png";
import UGJK from "../../../Assets/Images/10-ugjk.png";
import UFZ from "../../../Assets/Images/11-ufz.png";
import IREDS from "../../../Assets/Images/12-ireds.jpg";
import MASHT from "../../../Assets/Images/13-masht.png";
import AAB from "../../../Assets/Images/14-AAB.jpg";
import SEEU from "../../../Assets/Images/15-SEEU.jpg";

const OrganizingPartnersConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">ORGANIZING PARTNERS</h2>
      <div className="py-2 px-3 mt-3 organizing-partners">
        <p>
          <a href="http://www.unhz.eu" target="_blank">
            <img src={UHZ} alt="" width="139" height="139" />
          </a>
        </p>
        <p>
          <a
            href="https://www.ibcmitrovica.eu/"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={IBC} alt="" width="234" height="60" />
          </a>
          <a
            href="https://www.uniroma1.it/en/"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={SUR} alt="" width="249" height="130" />
          </a>
        </p>
        <p>
          <a
            href="http://www.unizg.hr/homepage/"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={UZ} alt="" width="143" height="142" /> 
          </a>
          <a
            href="https://www.unwe.bg/en/"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={UNWE} alt="" width="142" height="139" />
          </a>
          <a
            href="http://uni-pr.edu"
            style={{ margin: "0px 55px 42px 0px" }}
            target="_blank"
          >
            <img src={UP} alt="" width="137" height="137" />
          </a>
        </p>

        <a
          href="https://www.uni-prizren.com/"
          target="_blank"
          style={{ margin: "0px 55px 42px 0px" }}
        >
          <img src={UPZ} alt="" width="148" height="148" />
        </a>
        <p>
          <a
            href="https://www.umib.net/en/"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={UM} alt="" width="139" height="139" />
          </a>
          <a
            href="https://www.uni-gjilan.net/en/"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={UGJI} alt="" width="150" height="150" />
          </a>
          <a
            href="https://uni-gjk.org/"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={UGJK} alt="" width="150" height="150" />
          </a>
        </p>
        <p>
          <a
            href="https://www.ushaf.net/"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={UFZ} alt="" width="150" height="108" />
          </a>
          <a
            href="http://ireds-ks.org/"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={IREDS} alt="" width="150" height="66" />
          </a>
          <a
            href="https://masht.rks-gov.net/en"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={MASHT} alt="" width="150" height="169" />
          </a>
        </p>
        <p>
          <a
            href="https://aab-edu.net/en/"
            style={{ margin: "0px 55px 42px 0px" }}
            target="_blank"
          >
            <img src={AAB} alt="" width="254" height="55" />
          </a>
          <a
            href="https://www.seeu.edu.mk/"
            data-wp-editing="1"
            target="_blank"
            style={{ margin: "0px 55px 42px 0px" }}
          >
            <img src={SEEU} alt="" width="138" height="131" />
          </a>
        </p>
      </div>
    </>
  );
};

export default OrganizingPartnersConference4;
