import React, { useContext, useEffect, useState } from "react";
import "./ShowCaseArticle.css";
import { journalImage } from "../../JournalSettings";
import { JournalContext } from "../../store/JournalContext";
import { getBannerImages } from "../../endpoints";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Preloader from "../../UI/Preloader/Preloader";

function ShowCasedArticle() {
  const { showBanner, showBannerTitle } = useContext(JournalContext);
  const [bannerImages, setBannerImages] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (showBanner?.isActive) {
      getBannerImages()
        .then((res) => {
          setBannerImages(res.data);
        })
        .finally(() => setIsReady(true));
    }
  }, []);

  return (
    <>
      {!isReady && (
        <>
          {showBanner?.isActive && bannerImages.length === 0 && (
            <article className="shimmer-article showcaseContainer">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="shimmer"></div>
            </article>
          )}
        </>
      )}
      {isReady && (
        <>
          {showBanner?.isActive && bannerImages.length === 0 && (
            <article
              className="showcaseContainer"
              style={{
                backgroundImage: `url(${journalImage})`,
              }}
            >
              {showBannerTitle?.isActive &&
                showBannerTitle?.value.trim() !== "" && (
                  <div className="showcaseDetails">
                    <h2>{showBannerTitle?.value}</h2>
                  </div>
                )}
            </article>
          )}
          {showBanner?.isActive && bannerImages.length >= 1 && (
            <Carousel
              autoPlay
              interval={5000}
              infiniteLoop
              transitionType="fade"
              showStatus={false}
              stopOnHover={true}
            >
              {bannerImages.map((img, index) => (
                <div key={index}>
                  <img
                    src={`data:${img.imageContentType};base64,${img.image}`}
                    alt={img.imageName}
                    style={{
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {showBannerTitle?.isActive &&
                    showBannerTitle?.value.trim() !== "" && (
                      <div className="showcaseDetails">
                        <h2>{showBannerTitle?.value}</h2>
                      </div>
                    )}
                </div>
              ))}
            </Carousel>
          )}
        </>
      )}
    </>
  );
}

export default ShowCasedArticle;
