import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import "./globalCSS/variables.css";
import App from "./App";
import JournalContextProvider from "./store/JournalContext";
import SearchContextProvider from "./store/SearchContext";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SearchContextProvider>
      <JournalContextProvider>
        <App />
      </JournalContextProvider>
    </SearchContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
