import React from "react";
import abstractTemplate from "../../../Assets/Files/Abstract-Template.pdf";
import posterDesignTemplate from "../../../Assets/Files/Poster-Design-Template.pdf";

const PaperSubmissionConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">PAPER SUBMISSION</h2>
      <div className="p-2 mt-3">
        <p className="textJustify">
          Please use the following Google Form to submit your Full Paper as well
          as information:
        </p>
        <p
          style={{ margin: "15px 0" }}
          className="paper-submission textJustify"
        >
          <a
            target="_blank"
            href={
              "https://docs.google.com/forms/d/e/1FAIpQLScBcIegPqQG2XTWOTwhUt5ex342nBPPp_A_yuF6ZL-KOJWoXA/viewform"
            }
          >
            <strong>Full Paper Submission Form</strong>
          </a>
        </p>
        <p className="textJustify">
          <strong>Note</strong>: Please note that in order to submit your full
          paper you have to be signed in to a Google Account (Gmail). If you do
          not have a Google Account, you can create one by linking your current
          email addresses to a new Account.
        </p>
        <h4
          className="textJustify"
          style={{
            marginBottom: "15px",
            marginTop: "20px",
          }}
        >
          <strong style={{ color: "#0f358e" }}>
            ABSTRACT PREPARATION GUIDELINES
          </strong>
        </h4>
        <p className="textJustify">
          <p>
            If your abstract is accepted and you have paid the registration fee,
            you are encouraged to submit a full paper before October 10th, 2021
            for inclusion in the Conference Proceedings. Final papers must be
            submitted as an MS Word document in .doc or .docx format. Please
            <br />
            ensure that your paper is ready to go to press by the submission
            deadline, having thoroughly read and correct the paper before
            sending it.
          </p>
          <br />
          To send your paper save it with “
          <strong>Full Paper _ name and surname</strong>” of the
          first/corresponding author.
        </p>
        <h5
          className="textJustify"
          style={{
            color: "#0f358e",
            marginTop: "15px",
          }}
        >
          Formatting your paper
        </h5>
        <p
          className="textJustify paper-submission"
          style={{ marginBottom: "5px" }}
        >
          Download the{" "}
          <a href={abstractTemplate} target="_blank" rel="noopener">
            <strong>Final Paper Design Template</strong>
          </a>{" "}
          and carefully follow the formatting guidelines. Papers that do not
          follow the formatting guidelines will be returned for editing and may
          miss the proceedings publication.
        </p>
        <p>
          <em>
            <strong>The paper has to be submitted in English language.</strong>
          </em>
        </p>
        <h5
          className="textJustify"
          style={{
            color: "#0f358e",
            marginTop: "15px",
          }}
        >
          Full-Length Manuscript
        </h5>
        <p className="textJustify">
          Full Paper is limited to 15 pages normally, including all figures,
          tables, and references. No more pages will be allowed.
        </p>
        <h5
          className="textJustify"
          style={{
            color: "#0f358e",
            marginTop: "15px",
          }}
        >
          Conference Proceedings
        </h5>
        <p className="textJustify">
          Registered participants with an accepted abstract and/ or full paper
          will be published in the Conference Proceedings. The Conference
          Proceedings will be blind peer-reviewed by minimum two competent
          reviewers. The post-conference proceedings will submit to be indexed
          in the world’s leading source of information for businesses and
          professionals. The conference abstract book, proceedings; CD and
          certificate will be distributed to the participants at the conference
          desk.
        </p>
        <h5
          className="textJustify"
          style={{
            color: "#0f358e",
            marginTop: "15px",
          }}
        >
          Oral Presentation Details
        </h5>
        <p className="textJustify">
          Oral presentations will be 10 minutes plus 5 minutes of questions. All
          presentations should be prepared using PowerPoint and sent to the
          Conference Secretariat prior to the conference are kindly requested.
        </p>
        <h5
          className="textJustify"
          style={{
            color: "#0f358e",
            marginTop: "15px",
          }}
        >
          Poster Presentation Details
        </h5>
        <p className="textJustify">
          To keep good legibility of the poster, a maximum size of 70 cm wide
          and 100 cm high is recommended. The text and illustrations should be
          large enough to read from a distance of one meter. The poster boards
          will be numbered by the organizers. The number of your poster can be
          found in the Conference Program. Upon acceptance, the posters are
          expected to be printed by author(s) themselves based on criteria
          published.
        </p>
        <p className="textJustify paper-submission my-3">
          <a href={posterDesignTemplate} target="_blank" rel="noopener">
            <strong>Download the Poster Design Template</strong>
          </a>{" "}
          and follow the formatting guidelines.
        </p>
        <p className="textJustify">Working language: English.</p>
        <p className="textJustify" style={{ margin: "15px 0 10px 0" }}>
          <strong>Important Notice</strong>
        </p>
        <p className="textJustify">
          Each accepted paper (oral presentation or poster) should be
          accompanied by one registration (full or student).
        </p>
      </div>
    </>
  );
};

export default PaperSubmissionConference4;
