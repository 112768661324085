import React from "react";
import abstractTemplate from "../../../Assets/Files/Abstract-Template1.jsx.pdf";

const AbstractSubmissionConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">ABSTRACT SUBMISSION</h2>
      <div className="py-2 mt-3 px-3 abstract-submission">
        <div className="mb-2">
          <p className="pb-1">
            Please use the following Google Form to submit your Abstract as well
            as information:
          </p>
          <p className="pb-1">
            <a href="https://forms.gle/kuWzVgXUsAhXXNL49" target="_blank">
              Google Form link
            </a>
          </p>
          <p className="pb-1">
            <strong>Note</strong>: Please note that in order to submit your
            abstract you have to be signed in to a Google Account (Gmail). If
            you do not have a Google Account, you can create one by linking your
            current email addresses to a new Account.
          </p>
          <p className="pb-1">
            Create a new Google Account:{" "}
            <strong>
              <a
                target="_blank"
                href="https://accounts.google.com/signup/v2/webcreateaccount?hl=en&amp;flowName=GlifWebSignIn&amp;flowEntry=SignUp"
              >
                Link
              </a>
            </strong>
          </p>
          <p>
            Link an existing email to a new Google Account:{" "}
            <strong>
              <a
                href="https://support.google.com/accounts/answer/27441?hl=en"
                target="_blank"
              >
                Link
              </a>
            </strong>
          </p>
        </div>
        <div className="my-3">
          <h5 style={{ color: "#0f358e" }}>
            <strong>Abstract Preparation Guidelines</strong>
          </h5>
          <p>
            Abstracts will be submitted until <strong>01 March 2023</strong>.
            Abstracts will be peer reviewed against formatting criteria.
            Notification of acceptance of abstract: within{" "}
            <strong>15 days</strong> of the submission date.
          </p>
        </div>
        <div className="my-3">
          <h5 style={{ color: "#0f358e" }}>
            <strong>Formatting your abstract</strong>
          </h5>
          <p>
            <p>
              Download the{" "}
              <a href={abstractTemplate} target="_blank" rel="noopener">
                <strong>Abstract Design Template here</strong>
              </a>{" "}
              and carefully follow the formatting guidelines. Abstracts that do
              not follow the formatting guidelines will be returned for editing.
            </p>
          </p>
          <p>
            <p>
              <em>
                <strong>
                  Abstract has to be submitted in English language.
                </strong>
              </em>
            </p>
          </p>
          <p>
            <p>
              <strong>**Note: </strong>To download the file after opening the
              google drive view, click on File &gt; Download &gt; Microsoft Word
              (.docx).
            </p>
          </p>
        </div>
        <div className="my-3">
          <h5 style={{ color: "#0f358e" }}>
            <strong>Book of Abstracts</strong>
          </h5>
          <p>
            The accepted abstract will be published in the Conference Book of
            Abstracts after the participant is registered for conference. The
            conference abstract book, will be distributed to the participants at
            the conference desk, along with other conference materials.
          </p>
        </div>
      </div>
    </>
  );
};

export default AbstractSubmissionConference1;
