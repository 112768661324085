import React from "react";
import HomepageContent from "../components/HomepageComponents/HomepageContent";

const Homepage = () => {
  return (
    <>
      <HomepageContent />
    </>
  );
};

export default Homepage;
