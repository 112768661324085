import React, { useEffect, useRef, useState } from "react";
import { GetJournalIssuesList } from "../endpoints";
import Preloader from "../UI/Preloader/Preloader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { useContext } from "react";
import { JournalContext } from "../store/JournalContext";

function SpecialIssues() {
  const [issues, setIssues] = useState();
  const [areIssuesReady, setAreIssuesReady] = useState(false);
  const targetRefs = useRef({});
  const { sectionId } = useParams();
  const {hasSpecialIssues} = useContext(JournalContext)

  const { t } = useTranslation();

  useEffect(() => {
    GetJournalIssuesList().then((res) => {
      setIssues(res.data.journalIssues);
      setAreIssuesReady(true);
    });
  }, []);

  useEffect(() => {
    if (!targetRefs.current[sectionId]) return;
    Object.values(targetRefs.current).forEach((ref) => {
      ref.classList.remove("animate-issue");
    });
    targetRefs.current[sectionId].scrollIntoView({ behavior: "smooth" });
    targetRefs.current[sectionId].classList.add("animate-issue");
  }, [sectionId, areIssuesReady]);

  const handleRef = (id, ref) => {
    targetRefs.current[id] = ref;
  };

  return (
    <RouteInactiveChecker isActive={hasSpecialIssues?.isActive}>
    <div className="card">
      <h2 className="boardHeader">{t("specialIssues")}</h2>
      <Preloader isReady={areIssuesReady}>
        {issues?.map((issue, i) => {
          const deadline = new Date(issue.deadline);
          const deadlineMonth = deadline.getMonth() + 1;
          return (
            <div
              key={issue.id}
              id={issue.id}
              className="issueWrapper innerDiv"
              ref={(ref) =>
                handleRef(`issue${issue.id.split("-").join("")}`, ref)
              }
            >
              <h2 className="issueTitle">{issue.name}</h2>
              <p className="issueDescription">{issue.description}</p>
              <span>
                <b>{t("sections")}:</b>
                {issue.journalIssueSections.map((section, i) => (
                  <span className="text-muted" key={section.journalSection.id}>
                    {section.journalSection.name}
                    {i + 1 != issue.journalIssueSections.length && ", "}
                  </span>
                ))}
              </span>
              {issue.deadline && (
                <p className="text-danger">
                  <b>{t("deadline")}:</b> {deadline.getFullYear()}/
                  {deadlineMonth}/{deadline.getDate()}
                </p>
              )}
            </div>
          );
        })}
      </Preloader>
    </div>
    </RouteInactiveChecker>
  );
}

export default SpecialIssues;
