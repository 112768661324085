import React from "react";
import ConferenceProgramFinal_4May from "../../../Assets/Files/CONFERENCE-PROGRAM_Final_4-may.pdf";
import ConferenceProgramFinal_5May from "../../../Assets/Files/CONFERENCE-PROGRAM_Final_5-may.pdf";

const ProgramConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">CONFERENCE PROGRAM</h2>
      <div className="py-2 mt-3 px-3 conference-program">
        <p className="pb-1">
          <a href={ConferenceProgramFinal_4May} target="_blank">
            CONFERENCE PROGRAM_Final_4 may
          </a>
        </p>
        <p className="pb-1">
          <a href={ConferenceProgramFinal_5May} target="_blank">
            CONFERENCE PROGRAM_Final_5 may
          </a>
        </p>
        <p className="pb-1">
          The program includes the schedule for plenary presentations, thematic
          oral presentations, and poster presentations.
        </p>
        <p className="pb-1">
          <strong>Plenary presentations</strong>: will take place on May 4
          <sup>th</sup> afternoon and May 5<sup>th</sup> in the morning. Among
          our distinguished keynote speakers, Prof. Dan Shechtman, a Nobel Prize
          Laureate, will present on May 5<sup>th</sup>.
        </p>
        <p className="pb-1">
          <strong>Oral presentations:</strong> the thematic oral presentations
          sessions will take place on May 5<sup>th</sup>, with 15 minutes
          allocated for each presentation (10 minutes for the presentation,
          followed by a 5-minute question and answer session). We kindly request
          you to upload your presentation in PPT format by April 30<sup>th</sup>
          , 2023, using the{" "}
          <strong>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSccCNINWWDPalMRMWJh3LkD5s-qNqM5JrUpfNtOhR8erFmykw/viewform"
              data-saferedirecturl="https://www.google.com/url?q=https://docs.google.com/forms/d/e/1FAIpQLSccCNINWWDPalMRMWJh3LkD5s-qNqM5JrUpfNtOhR8erFmykw/viewform&amp;source=gmail&amp;ust=1682795606291000&amp;usg=AOvVaw0v947UPCIixio80v-pLlmC"
            >
              conference platform.
            </a>
          </strong>
        </p>
        <p className="pb-1">
          The <strong>poster presentation session</strong> will also be held on
          May 5<sup>th</sup> from 12:00 to 13:30h (combined with lunch). During
          this session, each poster-presenting author must stand in front of
          her/his respective poster to present and discuss their work with
          interested colleagues. Please prepare your posters according to the
          instructions provided on the{" "}
          <strong>
            <a
              target="_blank"
              href="/conference-1/about"
              data-saferedirecturl="https://www.google.com/url?q=https://researchcult.net/conference/icna-sta-scientific-conference-2023/&amp;source=gmail&amp;ust=1682795606291000&amp;usg=AOvVaw1Y7gpUxwb5AULf1cmjzPfs"
            >
              conference webpage
            </a>
          </strong>
          <strong>,</strong> and bring a printed version of your poster(s) to
          the conference venue.
        </p>
        <p>
          <p>
            Once again, thank you for your valuable contributions. We look
            forward to meeting you soon at the University of Prishtina.
          </p>
        </p>
      </div>
    </>
  );
};

export default ProgramConference1;
