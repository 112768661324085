import React, { createContext, useReducer, useState } from "react";

const SearchContext = createContext({
  searchParams: {},
  dispatchSearchParams: () => {},
  shouldTriggerSearch: false,
  setShouldTriggerSearch: () => {},
  searchResult: [],
  setSearchResult: () => {},
  showAdvancedSearch: false,
  setShowAdvancedSearch: () => {},
  isSearchCompleted:false,
  setIsSearchCompleted:()=>{},
  newPageNumber:1,
  setNewPageNumber:()=>{}
  
});
const defaultValues = {};

function searchParamsReducer(state, action) {
  const value = action.input.target.value.trim();

  if (action.type == "INPUT") {
    const input = action.input.target.name;

    if (value == "") {
      delete state[input];
      return state;
    }

    return { ...state, [input]: value };
  }
  if (action.type == "SELECT") {
    const input = action.input.target.title;

    if (value == "") {
      delete state[input];
      return state;
    }

    return { ...state, [input]: value };
  }
}

function SearchContextProvider({ children }) {
  const [searchParams, dispatchSearchParams] = useReducer(
    searchParamsReducer,
    defaultValues
  );

  const [shouldTriggerSearch, setShouldTriggerSearch] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [isSearchCompleted, setIsSearchCompleted] = useState(false)
  const [newPageNumber,setNewPageNumber] = useState(1)

  return (
    <SearchContext.Provider
      value={{
        searchParams: searchParams,
        dispatchSearchParams: dispatchSearchParams,
        shouldTriggerSearch: shouldTriggerSearch,
        setShouldTriggerSearch: setShouldTriggerSearch,
        searchResult: searchResult,
        setSearchResult: setSearchResult,
        showAdvancedSearch: showAdvancedFilter,
        setShowAdvancedSearch: setShowAdvancedFilter,
        isSearchCompleted:isSearchCompleted,
        setIsSearchCompleted:setIsSearchCompleted,
        setNewPageNumber:setNewPageNumber,
        newPageNumber:newPageNumber
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
export { SearchContext };
export default SearchContextProvider;
