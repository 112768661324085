import React from "react";
import "./PagingButtons.css";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

function PagingButtons({
  pageNumber,
  totalPages,
  totalCount,
  hasPreviousPage,
  hasNextPage,
  setNewPageNumber,
  hasPreviousPages = false,
  hasNextPages = false,
}) {
  function PagingButtons() {
    let html = [];
    for (let i = 1; i <= totalPages; i++) {
      html.push(
        <button
          className={`${pageNumber == i ? "active" : ""}`}
          onClick={() => setNewPageNumber(i)}
          key={i}
        >
          {i}
        </button>
      );
    }
    let firstPageIndex;
    let lastPageIndex;
    if (pageNumber > 3) {
      firstPageIndex = pageNumber - 3;
      lastPageIndex = firstPageIndex + 5;
    }

    if (pageNumber <= 3) {
      firstPageIndex = 0;
      lastPageIndex = firstPageIndex + 5;
    }

let newHtml =  html.slice(firstPageIndex, lastPageIndex);
    if(pageNumber - 3 > 0 && pageNumber -4 != 0) {
       newHtml.unshift("...")
       newHtml.unshift(<button
        onClick={() => setNewPageNumber(1)}
        key={400}
      >1</button>)
    }
    if(totalPages + 3 > pageNumber && totalPages - 3 >= pageNumber) {
      newHtml.push("...")
      newHtml.push(<button
       onClick={() => setNewPageNumber(totalPages)}
       key={500}
     >{totalPages}</button>)
   }

    return newHtml
  }

  return (
    <div className="pagingButtonsContainer">
      <button
        disabled={!hasPreviousPages}
        onClick={() => setNewPageNumber((prev) => prev - 1)}
      >
        <HiChevronLeft />
      </button>
      {PagingButtons()}
      <button
        disabled={!hasNextPages}
        onClick={() => setNewPageNumber((prev) => prev + 1)}
      >
        <HiChevronRight />
      </button>
    </div>
  );
}

export default PagingButtons;
