import React from "react";
import "./JournalActions.css";
import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialLinkedin,
} from "react-icons/ti";


function JournalActions() {
  return (
    <section className="journalActions">
      <div className="d-flex align-items-center" style={{ gap: "10px" }}>
        <div className="socialMediaItem d-flex align-items-center justify-content-center">
          <TiSocialFacebook className="socialMediaIcon" />
        </div>
        <div className="socialMediaItem d-flex align-items-center justify-content-center">
          <TiSocialTwitter className="socialMediaIcon" />
        </div>
        <div className="socialMediaItem d-flex align-items-center justify-content-center">
          <TiSocialLinkedin className="socialMediaIcon" />
        </div>
      </div>
    </section>
  );
}

export default JournalActions;
