import React from "react";

const SponsorshipConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">SPONSORSHIP</h2>
      <div className="py-2 mt-3 px-3 book-abstracts">
        <p className="pb-3">
          <strong>ResearchCult Erasmus + Project</strong>
        </p>
        <p>
          <strong>CEEPUS program:</strong>&nbsp;{" "}
          <a target="_blank" href="https://www.ceepus.info/">
            https://www.ceepus.info/
          </a>
        </p>
      </div>
    </>
  );
};

export default SponsorshipConference1;
