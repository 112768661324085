import React, { useContext, useEffect, useState } from "react";
import Select from "../Inputs/Select/Select";
import {
  GetArticleTypesList,
  GetJournalAdvancedSearchLists,
  GetSectionsSelectList,
} from "../../endpoints";
import Input from "../Inputs/Input/Input";
import { SearchContext } from "../../store/SearchContext";
import { useTranslation } from "react-i18next";
import { JournalContext } from "../../store/JournalContext";

function AdvancedSearch() {
  const [advancedSearchList, setAdvancedSearchList] = useState({});
  const { showAdvancedSearch } = useContext(SearchContext);
  const {
    hasSections,
    hasTopics,
    hasSpecialIssues,
    hasVolumes,
    hasArticleTypes,
  } = useContext(JournalContext);
  const { t } = useTranslation();

  const [publishedDate, setPublishedDate] = useState("");

  useEffect(() => {
    GetJournalAdvancedSearchLists().then((res) => {
      setAdvancedSearchList(res.data);
    });
  }, []);

  const handleDateChange = (event) => {
    setPublishedDate(event.target.value);
  };

  return (
    <div className={`advancedSearch ${showAdvancedSearch ? "showSearch" : ""}`}>
      <div className="mt-2 d-flex align-items-center w-100 flex-wrap">
        {hasSections?.isActive && (
          <div className="col-lg-6 col-12 p-1">
            <Select
              id={"article"}
              options={advancedSearchList.journalSections}
              defaultValue={t("allSections")}
              name={"journalSectionId"}
              triggerContext={true}
            />
          </div>
        )}
        {hasArticleTypes?.isActive && (
          <div className="col-lg-6 col-12 p-1">
            <Select
              id={"articleType"}
              options={advancedSearchList.journalArticleTypes}
              name={"articleTypeId"}
              defaultValue={t("allArticleTypes")}
              triggerContext={true}
            />
          </div>
        )}
        {hasTopics?.isActive && (
          <div className="col-lg-6 col-12 p-1">
            <Select
              type={"text"}
              name={"sectionResearchTopicId"}
              id={"articleTopic"}
              options={advancedSearchList.sectionResearchTopics}
              defaultValue={t("allTopics")}
              triggerContext={true}
            />
          </div>
        )}
        {hasSpecialIssues?.isActive && (
          <div className="col-lg-6 col-12 p-1">
            <Select
              type={"text"}
              name={"journalIssueId"}
              id={"JournalIssue"}
              options={advancedSearchList.journalIssues}
              defaultValue={t("allIssues")}
              triggerContext={true}
            />
          </div>
        )}
        {hasVolumes?.isActive && (
          <div className="col-lg-6 col-12 p-1">
            <Select
              type={"text"}
              name={"journalVolumeId"}
              id={"journalVolumes"}
              options={advancedSearchList.journalVolumes}
              defaultValue={t("allVolumes")}
              triggerContext={true}
            />
          </div>
        )}
        <div className="col-lg-6 col-12 p-1">
          <Input
            type={"date"}
            name={"publishedDate"}
            placeholder={t("publicationDate")}
            id={"publishedDate"}
            triggerContext={true}
            dataPlaceholder={t("publicationDate")}
            required
            ariaRequired={true}
          />
        </div>
      </div>
    </div>
  );
}

export default AdvancedSearch;
