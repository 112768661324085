import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetNewsById } from "../endpoints";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { JournalContext } from "../store/JournalContext";
import Preloader from "../UI/Preloader/Preloader";

const NewsDetails = () => {
  const [newsDetails, setNewsDetails] = useState();
  const [isReady, setIsReady] = useState(false);
  const { newsId } = useParams();
  const { hasNews } = useContext(JournalContext);

  useEffect(() => {
    GetNewsById(newsId)
      .then((res) => {
        setNewsDetails(res.data);
      })
      .finally(() => setIsReady(true));
  }, [newsId]);

  return (
    <Preloader isReady={isReady}>
      <RouteInactiveChecker isActive={hasNews?.isActive}>
        <div className="card">
          <h2 className="boardHeader">{newsDetails?.title}</h2>
          <div className="innerDiv">
            <div
              dangerouslySetInnerHTML={{ __html: newsDetails?.content }}
            ></div>
            <div className="mt-3">
              <span>
                <div>
                  <b>Published on: </b>{" "}
                  {(() => {
                    const dateObj = new Date(newsDetails?.publishedDate);
                    if (!isNaN(dateObj)) {
                      const year = dateObj.getFullYear();
                      const month = (dateObj.getMonth() + 1)
                        .toString()
                        .padStart(2, "0");
                      const day = dateObj.getDate().toString().padStart(2, "0");
                      const formattedDate = `${day}/${month}/${year}`;
                      return <span>{formattedDate}</span>;
                    }
                    return null;
                  })()}
                </div>
              </span>
            </div>
          </div>
        </div>
      </RouteInactiveChecker>
    </Preloader>
  );
};

export default NewsDetails;
