import React, { useContext } from "react";
import { JournalContext } from "../store/JournalContext";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { useTranslation } from "react-i18next";

function IndexAndArchiving() {
  const { journalIndexAndArchiving } = useContext(JournalContext);
  const { t } = useTranslation();

  return (
    <div className="card">
      <RouteInactiveChecker isActive={journalIndexAndArchiving?.isActive}>
        <h2 className="boardHeader">{t("index&archiving")}</h2>
        <div
          className="innerDiv"
          dangerouslySetInnerHTML={{ __html: journalIndexAndArchiving?.value }}
        ></div>
      </RouteInactiveChecker>
    </div>
  );
}

export default IndexAndArchiving;
