import React, { useContext } from "react";
import { JournalContext } from "../store/JournalContext";
import Preloader from "../UI/Preloader/Preloader";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { useTranslation } from "react-i18next";

function InstructionsForEditors() {
  const { journalEditorInstructions } = useContext(JournalContext);
  const { t } = useTranslation();

  return (
    <div className="card">
      <RouteInactiveChecker isActive={journalEditorInstructions?.isActive}>
        <h2 className="boardHeader">{t("instructionsForEditors")}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: journalEditorInstructions?.value }}
          className="innerDiv"
        ></div>
      </RouteInactiveChecker>
    </div>
  );
}

export default InstructionsForEditors;
