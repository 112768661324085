import React, { useContext } from "react";
import { JournalContext } from "../store/JournalContext";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { useTranslation } from "react-i18next";

function InstructionsForReviewers() {
  const { journalReviewerInstructions } = useContext(JournalContext);
  const { t } = useTranslation();

  return (
    <div className="card">
      <RouteInactiveChecker isActive={journalReviewerInstructions?.isActive}>
        <h2 className="boardHeader">{t("instructionsForReviewers")}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: journalReviewerInstructions?.value,
          }}
          className="innerDiv"
        ></div>
      </RouteInactiveChecker>
    </div>
  );
}

export default InstructionsForReviewers;
