import React, { useEffect, useState } from "react";
import { GetTop5News } from "../../endpoints";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NewsMenu = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);

  useEffect(() => {
    GetTop5News().then((res) => {
      setNews(res.data.news);
    });
  }, []);

  return (
    <section className=" mb-2 journalActions">
      <h2>{t("news")}</h2>
      {news?.length > 0 && (
        <>
          {news?.map((el) => {
            return (
              <Link
                key={el.id}
                className="newsLink mt-0"
                to={`/news-details/${el.id}`}
              >
                <h2>{el.title}</h2>
                <p className="publishedOn">
                  Published on:{" "}
                  {(() => {
                    const dateObj = new Date(el?.publishedDate);
                    if (!isNaN(dateObj)) {
                      const year = dateObj.getFullYear();
                      const month = (dateObj.getMonth() + 1)
                        .toString()
                        .padStart(2, "0");
                      const day = dateObj.getDate().toString().padStart(2, "0");
                      const formattedDate = `${day}/${month}/${year}`;
                      return <>{formattedDate}</>;
                    }
                    return null;
                  })()}
                </p>
              </Link>
            );
          })}{" "}
          <Link to={"/news"} className="issueLink mt-0">
            {t("showAllNews")}
          </Link>
        </>
      )}
      {news?.length == 0 && <p>{t("NoNewsHasbeenPostedYet")}!</p>}
    </section>
  );
};

export default NewsMenu;
