import React from "react";
import abstractBook from "../../../Assets/Files/Book-of-Abstracts_ICNA_STA_final.pdf";
import abstractTemplate from "../../../Assets/Files/Abstract-Template.pdf";

const AbstractSubmissionConference4 = () => {
  return (
    <>
      <div className="" id="conferencesContent4">
        <h2 className="boardHeader">ABSTRACT SUBMISSION</h2>
        <div className="py-2 px-3 mt-3">
          <p className="textJustify">
            Please use the following Google Form to submit your Abstract as well
            as information:
          </p>
          <p className="textJustify abstract-submission-form">
            <strong>
              <a
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfTTWQkFo7nOky2C2oarhnyODekuES5kLa3rwNPWn8UFc-oFQ/viewform"
              >
                Abstract Submission Form
              </a>
            </strong>
          </p>
          <p className="textJustify">
            <strong>Note</strong>: Please note that in order to submit your
            abstract you have to be signed in to a Google Account (Gmail). If
            you do not have a Google Account, you can create one by linking your
            current email addresses to a new Account.
          </p>
          <p className="textJustify abstract-submission-form">
            Create a new Google Account:{" "}
            <strong>
              <a href="https://accounts.google.com/signup/v2/webcreateaccount?hl=en&amp;flowName=GlifWebSignIn&amp;flowEntry=SignUp">
                Link
              </a>
            </strong>
          </p>
          <p className="textJustify abstract-submission-form">
            Link an existing email to a new Google Account:{" "}
            <strong>
              <a href="https://support.google.com/accounts/answer/27441?hl=en">
                Link
              </a>
            </strong>
          </p>
          <h4
            className="textJustify"
            style={{
              marginBottom: "15px",
              marginTop: "20px",
            }}
          >
            <strong style={{ color: "#0f358e" }}>
              Abstract Preparation Guidelines
            </strong>
          </h4>
          <p className="textJustify">
            Abstracts will be submitted until{" "}
            <strong>13 March 2022 (extended)</strong>. Abstracts will be peer
            reviewed against formatting criteria. Notification of acceptance of
            abstract: within <strong>10 days</strong> of the submission date.
          </p>
          <h5
            className="textJustify"
            style={{
              color: "#0f358e",
              marginTop: "15px",
            }}
          >
            Formatting your abstract
          </h5>
          <p className="textJustify abstract-submission-form">
            Download the{" "}
            <a href={abstractTemplate} target="_blank" rel="noopener">
              <strong>Abstract Design Template here</strong>
            </a>{" "}
            and carefully follow the formatting guidelines. Abstracts that do
            not follow the formatting guidelines will be returned for editing.
          </p>
          <p className="my-2">
            <em>
              <strong>Abstract has to be submitted in English language.</strong>
            </em>
          </p>
          <p className="textJustify">
            <strong>**Note: </strong>To download the file after opening the
            google drive view, click on File &gt; Download &gt; Microsoft Word
            (.docx).
          </p>
          <h5
            className="textJustify"
            style={{
              color: "#0f358e",
              marginTop: "15px",
            }}
          >
            Book of Abstracts
          </h5>
          <p className="textJustify">
            The accepted abstract will be published in the Conference Book of
            Abstracts after the participant is registered for conference. The
            conference abstract book, will be distributed to the participants at
            the conference desk, along with other conference materials.
          </p>
          <div className="document--listing">
            <div className="repeat">
              <a href={abstractBook} target="_blank">
                Book of Abstracts
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AbstractSubmissionConference4;
