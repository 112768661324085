import React, { useContext } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { journalLogo, journalLink } from "../../JournalSettings";
import HamburgerMenu from "../../UI/HamburgerMenu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { JournalContext } from "../../store/JournalContext";

function Navbar({ showLogo = true, showLanguages = true }) {
  const [showMenu, setShowMenu] = useState(false);
  const { t, i18n } = useTranslation();
  let languages = [];
  const { journalLanguages,journalName } = useContext(JournalContext);
  if (journalLanguages) languages = JSON.parse(journalLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  function HideMenu() {
    setShowMenu(false);
  }

  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
    localStorage.setItem("lng", lang);
    setSelectedLanguage(lang);
  };

  return (
    <div className="navHeader">
      <div className="d-flex justify-between align-items-center main800Background mainNavWrapper">
        <nav className="mainNav d-flex align-items-center">
          {showLogo && (
            <div id="logo">
              
                <NavLink to={"/"} className="d-flex align-items-center">
                  {journalLogo && <img src={journalLogo}></img>}
                  {journalName.toUpperCase()}
                </NavLink>
            </div>
          )}
          <HamburgerMenu setShowMenu={setShowMenu} showMenu={showMenu} />
          <ul
            className={`d-flex align-items-center ${showMenu && "active"}`}
            style={{ flex: 1 }}
          >
            <li className="login">
              <NavLink
                to={journalLink}
                onClick={HideMenu}
                target="_blank"
              >
                {t("login")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${journalLink}/auth/register`}
                onClick={HideMenu}
                target="_blank"
              >
                {t("register")}
              </NavLink>
            </li>
          </ul>
          {showLanguages && languages.length > 1 && (
            <>
              {/* <button
                className="changeLanguageBtn"
                onClick={() => handleLanguageChange("en-us")}
                disabled={i18n.resolvedLanguage === "en"}
              >
                En
              </button>
              <button
                className="changeLanguageBtn"
                onClick={() => handleLanguageChange("sq")}
                disabled={i18n.resolvedLanguage === "sq"}
              >
                Alb
              </button> */}
              <select value={selectedLanguage} onChange={handleLanguageChange}>
                {languages.map((lang) => (
                  <option key={lang} value={lang}>
                    {lang === "en-us" ? "En" : "Alb"}
                  </option>
                ))}
              </select>
            </>
          )}
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
