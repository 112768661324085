import React from "react";
import BookOfAbstracts from "../../../Assets/Files/BoA_ICNA_STA_2023_Final.pdf";
import BookofAbstractImg from "../../../Assets/Images/Book-of-Abstract-cover.jpg";

const BookOfAbstractsConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">BOOK OF ABSTRACTS</h2>
      <div className="py-2 mt-3 px-3 book-abstracts">
        <p className="pb-1">
          <a href={BookOfAbstracts} target="_blank">
            BoA_ICNA_STA_2023_Final
          </a>
        </p>
        <p>
          <a href={BookOfAbstracts} target="_blank">
            <img
              className="alignnone size-medium wp-image-1266"
              src={BookofAbstractImg}
              alt=""
              width="218"
              height="300"
            />
          </a>
        </p>
      </div>
    </>
  );
};

export default BookOfAbstractsConference1;
