import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./JournalMenu.css";
import NavLinkDisplayControl from "./NavLinkDisplayControl";
import { JournalContext } from "../../store/JournalContext";
import { useTranslation } from "react-i18next";

function JournalMenu({ setShowMenu }) {
  function HideMenu() {
    setShowMenu(false);
  }

  const journtalCtx = useContext(JournalContext);
  const { t } = useTranslation();

  return (
    <div className="journalMenuContainer">
      <span>{t("menu")}</span>
      <nav className="journalMenu">
        <NavLink to={"/"} onClick={HideMenu}>
          {t("home")}
        </NavLink>
        <NavLinkDisplayControl
          isActive={journtalCtx.journalAims?.isActive}
          to="/aims"
          onClick={HideMenu}
        >
          {t("aims&scope")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          isActive={journtalCtx.journalSubjectAreas?.isActive}
          to="/subject-areas"
          onClick={HideMenu}
        >
          {t("subjectAreas")}
        </NavLinkDisplayControl>
        {(journtalCtx.showAssociateEditors ||
          journtalCtx.showCoEditorsInChief ||
          journtalCtx.showEditors ||
          journtalCtx.showEditorsInChief ||
          journtalCtx.showTechnicalEditors ||
          journtalCtx.showSecretariatList) && (
          <NavLink to="/editorial-board" onClick={HideMenu}>
            {t("editorialBoard")}
          </NavLink>
        )}
        <NavLinkDisplayControl
          to="/reviewers"
          isActive={journtalCtx.showReviewers?.isActive}
          onClick={HideMenu}
        >
          {t("reviewers")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          to="/reviewer-board"
          isActive={journtalCtx.showReviewersBoard?.isActive}
          onClick={HideMenu}
        >
          {t("reviewersBoard")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          isActive={journtalCtx.journalIndexAndArchiving?.isActive}
          to="/index-archiving"
          onClick={HideMenu}
        >
          {t("index&archiving")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          to="/sections"
          isActive={journtalCtx.hasSections?.isActive}
          onClick={HideMenu}
        >
          {t("sections")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          to="/topics"
          isActive={journtalCtx.hasTopics?.isActive}
          onClick={HideMenu}
        >
          {t("topics")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          to="/special-issues/0"
          isActive={journtalCtx.hasSpecialIssues?.isActive}
          onClick={HideMenu}
        >
          {t("specialIssues")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          isActive={journtalCtx.journalAuthorInstructions?.isActive}
          to="/instructions-for-authors"
          onClick={HideMenu}
        >
          {t("instructionsForAuthors")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          isActive={journtalCtx.journalReviewerInstructions?.isActive}
          to="/instructions-for-reviewers"
          onClick={HideMenu}
        >
          {t("instructionsForReviewers")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          isActive={journtalCtx.journalEditorInstructions?.isActive}
          to="/instructions-for-editors"
          onClick={HideMenu}
        >
          {t("instructionsForEditors")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          isActive={journtalCtx.journalEditorialOffice?.isActive}
          to="/editorial-office"
          onClick={HideMenu}
        >
          {t("editorialOffice")}
        </NavLinkDisplayControl>
        <NavLinkDisplayControl
          isActive={journtalCtx.journalAbout?.isActive}
          to="/about"
          onClick={HideMenu}
        >
          {t("about")}
        </NavLinkDisplayControl>
      </nav>
    </div>
  );
}

export default JournalMenu;
