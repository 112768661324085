import React, { useState } from "react";
import HamburgerMenu from "../../../UI/HamburgerMenu";
import { NavLink, useNavigate } from "react-router-dom";

const ConferenceMenu4 = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="positionSticky sidebarLeftContainer">
      <HamburgerMenu setShowMenu={setShowMenu} showMenu={showMenu} />
      <div className={`sidebarLeft ${showMenu && "showMenu"}`}>
        <div>
          <div
            className="card p-0 mb-1"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
          >
            <h2
              onClick={() => navigate("/")}
              className="boardHeader m-0 backLink"
            >
              <i className="bi bi-arrow-left me-1"></i>
              Back
            </h2>
          </div>
          <div className="conferencesMenuContainer">
            <nav className="conferencesMenu">
              <NavLink to={"/conference-4/about"}>About</NavLink>
              <NavLink to={"/conference-4/topics"}>Topics</NavLink>
              <NavLink to={"/conference-4/conference-program"}>
                Conference Programs
              </NavLink>
              <NavLink to={"/conference-4/abstract-submission"}>
                Abstract Submission
              </NavLink>
              <NavLink to={"/conference-4/abstract-book"}>
                Book of Abstract
              </NavLink>
              <NavLink to={"/conference-4/paper-submission"}>
                Paper Submission
              </NavLink>
              <NavLink to={"/conference-4/supporting-journals"}>
                Supporting Scientific Journals
              </NavLink>
              <NavLink to={"/conference-4/conference-committees"}>
                Conference Committees
              </NavLink>

              <NavLink to={"/conference-4/sponsorship"}>Sponsorship</NavLink>
              <NavLink to={"/conference-4/venue"}>Venue</NavLink>
              <NavLink to={"/conference-4/organizing-partners"}>
                Organizing Partners
              </NavLink>
              <NavLink to={"/conference-4/contact"}>Contact</NavLink>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceMenu4;
