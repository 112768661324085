import React, { useContext, useEffect } from "react";
import ShowCasedArticle from "./ShowCasedArticle";
import "./HomepageContent.css";
import ArticleList from "./ArticleList";
import { JournalContext } from "../../store/JournalContext";

function HomepageContent() {
  const { journalDescription, journalLongName, journalName, showBanner } =
    useContext(JournalContext);

  return (
    <div className="articlesList">
      <ShowCasedArticle />
      {journalDescription?.isActive && (
        <div
          className={`contentfulDiv journalDescription ${
            !showBanner?.isActive ? "hiddenBanner" : ""
          }`}
        >
          <h2 className="text-center w-75">
            {journalLongName} ({journalName})
          </h2>
          <div
            className="descriptionContent"
            dangerouslySetInnerHTML={{ __html: journalDescription?.value }}
          ></div>
        </div>
      )}

      <ArticleList />
    </div>
  );
}

export default HomepageContent;
