import React from "react";

const VenueConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">VENUE</h2>
      <div className="py-2 mt-3 px-3 book-abstracts">
        <p className="pb-3">
          Premisses of the University of Prishtina “Hasan prishtina”.
        </p>
        <p>Prishtina, Kosova</p>
      </div>
    </>
  );
};

export default VenueConference1;
