import React from "react";

const ThematicTopicsConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">CONFERENCE THEMATIC TOPICS</h2>
      <div className="py-2 mt-3 px-3">
        <div className="mb-3">
          <h5 className="mb-2">
            <span className="headerColor">
              <strong>The Scope of the ICNA-STA Conference</strong>
            </span>
          </h5>
          <p>
            The conference is organized as a multidisciplinary international
            conference, in the following scientific disciplines but not limited
            to, thus enabling wide participation of researchers.
          </p>
        </div>
        <div className="mt-1">
          <ol className="px-3">
            <li className="mb-3">
              <strong>Natural & Environmental Sciences</strong>
              <ol className="mt-2">
                <li>Environmental analysis, sustainability and development</li>
                <li>Applied ecology</li>
                <li>Air pollution, water, soil and waste management</li>
                <li>Socioeconomics & environmental management</li>
                <li>Applied chemistry/physics</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>Agricultural Production and Food Safety</strong>
              <ol className="mt-2">
                <li>Agricultural production</li>
                <li>Food quality and safety</li>
                <li>Biotechnology and food technology</li>
                <li>Plant pathology</li>
                <li>Horticulture</li>
                <li>Veterinary sciences</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>Medical/Health Sciences</strong>
              <ol className="mt-2">
                <li>Medical sciences</li>
                <li>Health sciences</li>
                <li>Nutrition sciences</li>
                <li>Pharmaceutical studies</li>
                <li>Dental studies</li>
                <li>Other medical & healthcare studies</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>Education Sciences</strong>
              <ol className="mt-2">
                <li>Adult and continuing education</li>
                <li>E-learning</li>
                <li>Early childhood education</li>
                <li>Primary and secondary education</li>
                <li>Curriculum development</li>
                <li>Educational psychology</li>
                <li>Reforms in education</li>
                <li>Inclusion in education</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>Economics, Finance, Management and Marketing</strong>
              <ol className="mt-2">
                <li>Changing business world</li>
                <li>Finance in the digital area</li>
                <li>Risk management</li>
                <li>Human resource management</li>
                <li>Leadership challenges and opportunities</li>
                <li>Future proofing business and career</li>
                <li>Tourism Development</li>
                <li>Sustainable Tourism Development</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>Information technology and communication</strong>
              <ol className="mt-2">
                <li>Communications model</li>
                <li>Digitalization and data communications and processing</li>
                <li>Computer-communication networks</li>
                <li>Information and communication technology</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>Technology & Engineering</strong>
              <ol className="mt-2">
                <li>Applied technological sciences</li>
                <li>Architecture and engineering</li>
                <li>Energy</li>
                <li>Modeling and simulation of mechatronic systems</li>
                <li>Materials and mechanical engineering</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>Social Sciences and Human Rights</strong>
              <ol className="mt-2">
                <li>Psychological and behavioral studies</li>
                <li>Developmental psychology</li>
                <li>Social work and welfare states</li>
                <li>Political and public administration sciences</li>
                <li>International relations, European studies</li>
                <li>Ethnology and Anthropology</li>
                <li>Legal studies</li>
                <li>Human rigs and gender issues</li>
                <li>Marriage, family and education</li>
                <li>Culture, Arts, Public Sphere and Communication</li>
                <li>Migration and Diaspora</li>
                <li>Ethnic Relations, Nationalism, Human Rights</li>
                <li>Stereotypes and prejudices: xenophobia & racism</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>Philological Sciences and Arts</strong>
              <ol className="mt-2">
                <li>Linguistic studies</li>
                <li>Culture and Literature studies</li>
                <li>Innovation in Language Teaching</li>
                <li>Philosophy of art</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>
                Intellectual Property Protection in the Metaverse and AI
              </strong>
              <ol className="mt-2">
                <li>Protecting IP in virtual reality</li>
                <li>AI and IP Law</li>
                <li>Blockchain and IP Protection</li>
                <li>IP in the gaming industry</li>
                <li>AI-generated content and IP rights</li>
                <li>Smart contracts and IP protection</li>
                <li>
                  Ethical considerations in IP protection for AI and virtual
                  reality
                </li>
                <li>Data privacy and IP in the metaverse</li>
                <li>International IP laws and the metaverse</li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default ThematicTopicsConference1;
