import React, { useEffect, useState } from "react";
import { GetJournalSectionsList } from "../endpoints";
import Preloader from "../UI/Preloader/Preloader";
import { useTranslation } from "react-i18next";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { useContext } from "react";
import { JournalContext } from "../store/JournalContext";

function Sections() {
  const [sections, setSections] = useState([]);
  const [areSectionsLoaded, setAreSectionsLoaded] = useState(false);
  const { t } = useTranslation();
  const {hasSections} = useContext(JournalContext)

  useEffect(() => {
    GetJournalSectionsList().then((res) => setSections(res.data.sections));
    setAreSectionsLoaded(true);
  }, []);

  return (
    <RouteInactiveChecker isActive={hasSections?.isActive}>
    <div className="card">
      <h2 className="boardHeader">{t("sections")}</h2>
      <Preloader isReady={areSectionsLoaded}>
        {sections?.map((section) => {
          return (
            <div className="innerDiv topicContainer" key={section.id}>
              <h2>{section.name}</h2>
              {/* { section.sectionResearchTopics.length > 0 && <span>Topics:</span>} */}
              <span>
                <b>{t("topics")}:</b>{" "}
                {section.sectionResearchTopics.map((topic, i) => (
                  <span className="text-muted" key={topic.id}>
                    {topic.name}
                    {i + 1 != section.sectionResearchTopics.length && ", "}
                  </span>
                ))}
              </span>
            </div>
          );
        })}
      </Preloader>
    </div>
    </RouteInactiveChecker>
  );
}

export default Sections;
