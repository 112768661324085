import React, { useEffect, useState } from "react";
import { GetArticleDetails } from "../endpoints";
import { Link, useParams } from "react-router-dom";
import Preloader from "../UI/Preloader/Preloader";
import { RiUser3Line } from "react-icons/ri";
import { VscFileSymlinkFile } from "react-icons/vsc";
import CustomizableLineChart from "../components/Charts/CustomizableLineChart";
import { useTranslation } from "react-i18next";

const Article = () => {
  const [articleData, setArticleData] = useState();
  const { id } = useParams();
  const [totalViews, setTotalViews] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    GetArticleDetails(id)
      .then((res) => {
        setArticleData({
          ...res.data,
          chartData: res.data.chartData.map((obj) => {
            return {
              name: obj.name,
              views: obj.amount,
            };
          }),
        });

        let total = 0;
        res.data.chartData.map((obj) => {
          total += obj.amount;
        });
        setTotalViews(total);
      })
      .finally(() => setIsReady(true));
  }, []);

  return (
    <div className="card">
      <h2 className="boardHeader">{t("article")}</h2>
      <Preloader isReady={isReady}>
        <div className="articleWrapper innerDiv">
          <h2 className="articleTitle">{articleData?.title}</h2>
          <span className="d-flex">
            <div>
              {articleData?.authors.map((author, index) => {
                return (
                  <div className="authorInfoWrapper" key={index}>
                    {author?.image ? (
                      <img
                        src={`data:image/*;base64,${author?.image}`}
                        alt=""
                      />
                    ) : (
                      <RiUser3Line />
                    )}
                    <div className="">
                      <b className="mb-1">
                        {author.firstName.charAt(0).toUpperCase() +
                          author.firstName.slice(1)}{" "}
                        {author.middleName.charAt(0).toUpperCase() +
                          author.middleName.slice(1)}{" "}
                        {author.lastName.charAt(0).toUpperCase() +
                          author.lastName.slice(1)}
                      </b>
                      <p>
                        {author.university && `${author.university}, `}
                        {author.department && `${author.department}, `}
                        {author.country && author.country}
                      </p>

                      <p>{author.email}</p>
                    </div>
                    {author.isCorrespondingAuthor && (
                      <a
                        href={`mailto:${author.email}`}
                        style={{ height: "fit-content" }}
                      >
                        <i
                          className="bi bi-envelope "
                          style={{ cursor: "pointer" }}
                        />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          </span>
          <p className="">{articleData?.abstract}</p>
          <span className="d-flex mb-2">
            <b className="me-1">{t("keywords")}: </b>
            <p>{articleData?.keywords}</p>
          </span>

          <Link
            to={`/view-article/${articleData?.articleId}`}
            target="_blank"
            className="btn previewBtn"
          >
            <div className="">
              <VscFileSymlinkFile /> {t("viewArticle")}
            </div>
          </Link>
        </div>
        <div className="chartWrapper mt-3">
          <p>{t("articleViewsStatistics")}</p>
          <CustomizableLineChart
            data={articleData?.chartData}
            color={"var(--main05)"}
            datakey={"views"}
          />
          <p>
            {t("totalViews")}: <b>{totalViews}</b>
          </p>
        </div>
      </Preloader>
    </div>
  );
};

export default Article;
