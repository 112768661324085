import React from "react";
import Input from "../Inputs/Input/Input";
import "./SidebarRight.css";
import IssuesMenu from "./IssuesMenu";
import { GUID } from "../../JournalSettings";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { JournalContext } from "../../store/JournalContext";
import { JournalId } from "../../Enums";
import NewsMenu from "./NewsMenu";

function SidebarRight() {
  const { t } = useTranslation();
  const { hasNews } = useContext(JournalContext);

  return (
    <section className="sidebarRight positionSticky">
      {GUID === JournalId.ICNA_STA && (
        <div className="card mb-2">
          <h2>Conferences</h2>
          <Link to={"/conference-1/about"} className="conferenceLink">
            May 4, 2023
          </Link>
          {/* <Link to={"/conference/conference-2"} className="conferenceLink">
            April 30, 2021
          </Link> */}
          {/* <Link to={"/conference/conference-3"} className="conferenceLink">
            October 30, 2022
          </Link> */}
          <Link to={"/conference-4/about"} className="conferenceLink">
            April 15, 2022
          </Link>
        </div>
      )}
      {hasNews?.isActive && (
        <div className="card mb-2">
          <NewsMenu />
        </div>
      )}
      <div className="card subscibeSidebar">
        <h2>Email</h2>
        <p>{t("addEmailToReceiveIssues")}</p>
        <Input
          type="email"
          id={"subscriptionEmail"}
          name={"subscriptionEmail"}
          placeholder={t("enterEmail")}
        />
        <button className="btn mainBtn">{t("subscribe")}</button>
      </div>

      <div className="card mt-2">
        <IssuesMenu />
      </div>
    </section>
  );
}

export default SidebarRight;
