import React from "react";
import PosterDesignTemplate1 from "../../../Assets/Files/Poster-Design-Template1.pdf";

const PosterPresentationConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">POSTER PRESENTATION</h2>
      <div className="py-2 mt-3 px-3 book-abstracts">
        <div className="mb-3">
          <h5 className="mb-2">
            <span className="headerColor">
              <strong>Poster submission</strong>
            </span>
          </h5>
          <p className="pb-1">
            Please use the following Google Form to submit your Poster as well
            as information:{" "}
            <p className="mt-25">
              <a href="https://forms.gle/kuWzVgXUsAhXXNL49" target="_blank">
                Google Form link
              </a>
            </p>
          </p>
          <p className="pb-1">
            <strong>Note</strong>: Please note that in order to submit your
            poster you have to be signed in to a Google Account (Gmail). If you
            do not have a Google Account, you can create one by linking your
            current email addresses to a new Account.
          </p>
          <p className="pb-1">
            Create a new Google Account:{" "}
            <strong>
              <a
                target="_blank"
                href="https://accounts.google.com/signup/v2/webcreateaccount?hl=en&amp;flowName=GlifWebSignIn&amp;flowEntry=SignUp"
              >
                Link
              </a>
            </strong>
          </p>
          <p>
            Link an existing email to a new Google Account:{" "}
            <strong>
              <a
                target="_blank"
                href="https://support.google.com/accounts/answer/27441?hl=en"
              >
                Link
              </a>
            </strong>
          </p>
        </div>
        <div className="mb-3">
          <h5 className="mb-2">
            <span className="headerColor">
              <strong>Poster Preparation Guidelines</strong>
            </span>
          </h5>
          <p>
            Posters will be accepted until <strong>15 March 2023</strong>.
            Posters will be peer reviewed against formatting criteria.
            Notification of acceptance of poster: within{" "}
            <strong>10&nbsp;days</strong> of the submission date.
          </p>
        </div>
        <div className="mb-3">
          <h5 className="mb-2">
            <span className="headerColor">
              <strong>Formatting your poster</strong>
            </span>
          </h5>
          <p className="pb-1">
            To keep good legibility of the poster, a maximum size of 70 cm wide
            and 100 cm high is recommended. The text and illustrations should be
            large enough to read from a distance of one meter. The poster boards
            will be numbered by the organizers. The number of your poster can be
            found in the Conference Program. Upon acceptance, the posters are
            expected to be printed by author(s) themselves based on criteria
            published.
          </p>
          <p className="pb-1">
            <a href={PosterDesignTemplate1} target="_blank" rel="noopener">
              <strong>Download the Poster Design Template</strong>
            </a>{" "}
            and follow the formatting guidelines.
          </p>
          <p className="pb-1">Posters to be submitted in: English.</p>
          <p className="pb-1">
            <strong>Important Notice</strong>
          </p>
          <p>
            Each accepted poster presentation should be accompanied by one
            registration.
          </p>
        </div>
      </div>
    </>
  );
};

export default PosterPresentationConference1;
