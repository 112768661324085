import axios from "axios";
import { baseURL, GUID } from "./JournalSettings";
import { ArticleStatus } from "./Enums";

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

async function GetJournal() {
  return instance
    .get(`/Journals/GetJournalById?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetJournalTopicsList() {
  return instance
    .get(`/Journals/GetJournalTopicsList?journalId=${GUID}&status=1`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetSectionsSelectList() {
  return instance
    .get(`/Journals/GetSectionsSelectList?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetArticleTypesList() {
  return instance
    .get(`/Article/GetArticleTypesList?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetJournalUsers(roles, roletype) {
  const body = {
    journalId: GUID,
    roles: roles,
    status: 1,
    roletype: roletype,
  };
  return instance.post("/User/GetUsersByRoleList", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetArticlesList() {
  const body = {
    journalId: GUID,
    status: ArticleStatus.Published,
    pageSize: 5,
  };
  return instance
    .post(`/Article/GetArticlesList?journalId`, body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetArticlesListWithParams(searchParams) {
  const body = {
    ...searchParams,
    journalId: GUID,
    status: ArticleStatus.Published,
  };
  return instance.post(`/Article/GetArticlesList`, body).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetJournalIssuesList() {
  return instance
    .get(`Journals/GetJournalIssuesList?journalId=${GUID}&status=1`)
    .then((response) => Promise.resolve(response));
}

async function GetJournalIssuesListForMenu() {
  return instance
    .get(`Journals/GetJournalIssuesList?journalId=${GUID}&status=1&pageSize=3`)
    .then((response) => Promise.resolve(response));
}

async function GetJournalSectionsList() {
  return instance
    .get(`Journals/GetJournalSectionsList?journalId=${GUID}&status=1`)
    .then((response) => Promise.resolve(response));
}

async function GetJournalAdvancedSearchLists() {
  return instance
    .get(`/Article/GetArticleAdvancedSearchLists?journalId=${GUID}`)
    .then((response) => Promise.resolve(response));
}

async function GetArticleDetails(id) {
  return instance
    .get(`/Article/GetArticleDetails_Portal?articleId=${id}`)
    .then((response) => Promise.resolve(response));
}

async function downloadPublishedArticleFile(articleFileId) {
  return instance
    .get(`/Article/DownloadPublishedArticleFile?articleFileId=${articleFileId}`)
    .then((response) => Promise.resolve(response));
}

async function GetAppSettingDetails() {
  return instance
    .get(`/AppSettings/getAppSettingDetails?journalId=${GUID}`)
    .then((res) => res);
}

async function getAppSettingDetails() {
  return instance
    .get(`/AppSettings/getAppSettingDetails?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetTop5News() {
  return instance
    .get(`/News/GetTop5News?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetNewsById(id) {
  return instance
    .get(`/News/GetNewsById?Id=${id}&journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetNews(statusId) {
  return instance
    .get(`/News/GetNews?journalId=${GUID}&status=${statusId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function getBannerImages() {
  return instance
    .get(`/AppSettings/getBannerImages?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

export {
  GetJournal,
  GetJournalTopicsList,
  GetJournalUsers,
  GetArticlesList,
  GetJournalIssuesList,
  GetArticleTypesList,
  GetSectionsSelectList,
  GetArticlesListWithParams,
  GetJournalSectionsList,
  GetJournalAdvancedSearchLists,
  GetJournalIssuesListForMenu,
  GetArticleDetails,
  GetAppSettingDetails,
  downloadPublishedArticleFile,
  getAppSettingDetails,
  GetTop5News,
  GetNewsById,
  GetNews,
  getBannerImages,
};
