import React, { useState, useEffect } from "react";
import { downloadPublishedArticleFile } from "../../endpoints";
import { useParams } from "react-router-dom";
import Preloader from "../../UI/Preloader/Preloader";

const ArticleFile = () => {
  const [articleData, setArticleData] = useState();
  const { id } = useParams();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    downloadPublishedArticleFile(id)
      .then((res) => {
        setArticleData(res.data);
      })
      .finally(() => setIsReady(true));
  }, []);

  return (
    <Preloader isReady={isReady} minHeight={"100vh"}>
      <iframe
        src={`data:${articleData?.articleFileName};base64,${articleData?.articleFile}`}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          width: "100%",
          height: "100%",
          border: "none",
          margin: "0",
          padding: "0",
          overflow: "hidden",
          zIndex: "999999",
        }}
      ></iframe>
    </Preloader>
  );
};

export default ArticleFile;
