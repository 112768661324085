import React, { useState } from "react";
import JournalMenu from "./JournalMenu";
import HamburgerMenu from "../../UI/HamburgerMenu";

const SidearLeft = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="positionSticky sidebarLeftContainer">
      <HamburgerMenu setShowMenu={setShowMenu} showMenu={showMenu} />
      <div className={` sidebarLeft ${showMenu && "showMenu"}`}>
        <JournalMenu setShowMenu={setShowMenu} />
      </div>
    </div>
  );
};

export default SidearLeft;
