import React from "react";

const TopicsConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">Topics</h2>
      <div className="py-2 px-3 mt-3">
        <h4 className="textJustify" style={{ marginBottom: "15px" }}>
          <strong style={{ color: "#0f358e" }}>
            The Scope of the ICNA-STA Conference
          </strong>
        </h4>
        <p className="textJustify" style={{ marginBottom: "15px" }}>
          The conference will be organized in a variety of the following
          scientific disciplines, organized in several thematic sessions, which
          will allow wide participation of researchers.
        </p>
        <div className="mb-3">
          <ol className="px-3">
            <li className="mb-3">
              <strong>EDUCATION & TRAINING</strong>
              <ol className="mt-2">
                <li>Adult and Continuing Education</li>
                <li>E-learning</li>
                <li>Early Childhood Education</li>
                <li>Elementary Education</li>
                <li>Teacher Education</li>
                <li>Curriculum Development &amp; Pedagogy</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>
                ECONOMICS, FINANCE &amp; BUSINESS ENVIRONMENT - Embracing Change
                &amp; Transformation
              </strong>
              <ol className="mt-2">
                <li>Changing Business World</li>
                <li>
                  Resilience &amp; Reinvention - Overcoming hardships and
                  challenges
                </li>
                <li>Finance in the Digital Area, accounting Intelligence</li>
                <li>Demographic, Social/Lifestyle, and Environmental Trends</li>
                <li>Managing Risk in a COVID World</li>
                <li>Business Performance in a COVID-19 Situation</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>
                MANAGEMENT, LEADERSHIP &amp; MARKETING - Embracing Change &amp;
                Transformation
              </strong>
              <ol className="mt-2">
                <li> Contemporary Human Resource Management</li>
                <li>
                  Technology Updates - Social media, Marketing Automation,
                  Cybersecurity
                </li>
                <li>Leadership challenges and opportunities during Covid-19</li>
                <li>Game changer - Changing the Game</li>
                <li>Future proofing business and career</li>
                <li>The effects of COVID-19 on Tourism Development</li>
                <li>Sustainable Tourism Development</li>
                <li>
                  Industry 4.0 - Digitalization and prospects for economic
                  growth
                </li>
                <li>Professions of the future</li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>AGROBUSINESS SUSTAINIBILITY</strong>
              <ol className="mt-2">
                <li>
                  <p className="textJustify">Food quality and safety</p>
                </li>
                <li>
                  <p className="textJustify">
                    Agricultural and Environmental Science
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Biotechnology and Food Technology
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Plant Pathology &amp; Microbiology
                  </p>
                </li>
                <li>
                  <p className="textJustify">Horticulture &amp; Agriculture</p>
                </li>
                <li>
                  <p> Bioeconomy and smart green growth</p>
                </li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>
                THE ADEQUACY OF INTERNATIONAL HUMAN RIGHTS FRAMEWORK UNDER THE
                COVID -19 PANDEMIC CONDITIONS / LEGAL STUDIES
              </strong>{" "}
              <ol className="mt-2">
                <li>
                  <p className="textJustify">
                    Innovations in Public Administration and Governance:
                    Accommodating exercise of public authorities under Covid -19
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Operating online and the legal consequences: Cyber security
                    - privacy and cyber-crime
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Judicialisation of Covid-19 measures: international and
                    domestic practice
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Vulnerable groups and Covid-19: women, children, migrants.
                  </p>
                </li>
                <li>
                  <p className="textJustify">Legal studies</p>
                </li>
                <li>
                  <p className="textJustify">
                    Stereotypes and prejudices: xenophobia &amp; racism
                  </p>
                </li>
              </ol>
            </li>

            <li className="mb-3">
              <strong>TECHNOLOGY &amp; ENGINEERING</strong>{" "}
              <ol className="mt-2">
                <li>
                  <p className="textJustify">Applied Sciences</p>
                </li>
                <li>
                  <p className="textJustify">
                    Computer and Information Technology
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Architecture and Engineering Technology
                  </p>
                </li>
                <li>
                  <p className="textJustify">Power and Energy Engineering</p>
                </li>
                <li>
                  <p className="textJustify">
                    Modeling and Simulation of Mechatronic Systems
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Materials and Mechanical Engineering
                  </p>
                </li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>MEDICAL/HEALTH SCIENCES</strong>
              <ol className="mt-2">
                <li>
                  <p className="textJustify">Reforming Healthcare Delivery</p>
                </li>
                <li>
                  <p className="textJustify">
                    Innovative Models in Behavioral Health
                  </p>
                </li>
                <li>
                  <p className="textJustify">Consumer Health and Wellness</p>
                </li>
                <li>
                  <p className="textJustify">
                    Digital Health in improving patient's services
                  </p>
                </li>
                <li>
                  <p className="textJustify">Other medical studies</p>
                </li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>NATURAL &amp; ENVIRONMENTAL SCIENCES</strong>
              <ol className="mt-2">
                <li>
                  <p className="textJustify">
                    Environmental Analysis, sustainability and development
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Applied Ecology, air pollution, water, soil and waste
                    management
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Socioeconomics &amp; Environmental Management
                  </p>
                </li>
                <li>
                  <p className="textJustify">Landscapes &amp; Scales</p>
                </li>
                <li>
                  <p className="textJustify">
                    Modelling and Chemicals in the Environment
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Physics applied to accelerators and experimental particle
                    physics
                  </p>
                </li>
                <li>
                  <p className="textJustify">Medical Applications of Physics</p>
                </li>
                <li>
                  <p className="textJustify">
                    Chemistry/physics applied to the analysis of archaeological
                    materials
                  </p>
                </li>
              </ol>
            </li>
            <li className="mb-3">
              <strong>SOCIAL SCIENCES</strong>{" "}
              <ol className="mt-2">
                <li>
                  <p className="textJustify">
                    Psychological and behavioral studies
                  </p>
                </li>
                <li>
                  <p className="textJustify">Social work and welfare states</p>
                </li>
                <li>
                  <p className="textJustify">
                    Political and public administration sciences
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    International Relations, European Studies
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Sociological and anthropological studies
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Statistics applied to social and economic sciences
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Official statistics for policy making and evaluation
                  </p>
                </li>
                <li>
                  <p className="textJustify">
                    Big Data, machine learning and opinion mining
                  </p>
                </li>
              </ol>
            </li>

            <li className="mb-3">
              <strong>HUMANITIES &amp; PHILOLOGICAL SCIENCES</strong>{" "}
              <ol className="mt-2">
                <li>
                  <p className="textJustify">Applied Anthropology</p>
                </li>
                <li>
                  <p className="textJustify">Cultural Anthropology</p>
                </li>
                <li>
                  <p className="textJustify">Developmental Psychology</p>
                </li>
                <li>
                  <p className="textJustify">Educational Psychology</p>
                </li>
                <li>
                  <p className="textJustify"> Philosophy of Art</p>
                </li>
                <li>
                  <p className="textJustify"> Ethnography</p>
                </li>
                <li>
                  <p className="textJustify">
                    Ethnology and Linguistic Anthropology
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default TopicsConference4;
