import React from "react";
import { NavLink } from "react-router-dom";

function NavLinkDisplayControl({ isActive, children, to, onClick }) {
  return (
    <>
      {isActive && (
        <NavLink to={to} onClick={onClick}>
          {children}
        </NavLink>
      )}
    </>
  );
}

export default NavLinkDisplayControl;
