import React from "react";

const OrganizingCommitteeConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">ORGANIZING COMMITTEE</h2>
      <div className="py-2 mt-3 px-3">
        <div className="mb-3">
          <h5 className="mb-2">
            <span className="headerColor">
              <strong>Chair of the Conference</strong>
            </span>
          </h5>
          <p>
            <strong>Avni Hajdari, </strong>
            <em>University of Prishtina “Hasan Prishtina” </em>
          </p>
        </div>
        <div className="mb-3">
          <h5>
            <span className="headerColor">
              <strong>Co-Chairs of the Conference</strong>
            </span>
          </h5>
          <p>
            <strong>Bujar Gallopeni</strong>,&nbsp;
            <em>International Business College Mitrovica</em>
          </p>
          <p>
            <span lang="SQ">
              <strong>Sefer Avdiaj,</strong>{" "}
              <em>University of Prishtina “Hasan Prishtina” </em>
            </span>
          </p>
        </div>
        <div>
          <h5 className="mb-2">
            <span className="headerColor">Members</span>
          </h5>
          <p>
            <strong>Names and Surname - Institution </strong>
          </p>
          <p className="pb-1">
            Adelina Rakaj - University of Prizren “Ukshin Hoti”
          </p>
          <p className="pb-1">
            Aferdita Berisha Shaqiri - University of Prishtina “Hasan Prishtina
          </p>
          <p className="pb-1">
            Agron Bajraktari - University of Applied Sciences Ferizaj
          </p>
          <p className="pb-1">
            Agron Hajdari - International Business College Mitrovica
          </p>
          <p className="pb-1">
            Alma Shehu Lokaj - University of Peja “Haxhi Zeka”
          </p>
          <p className="pb-1">
            Argjira Juniku-Shkololli - University of Prishtina “Hasan Prishtina”
          </p>
          <p className="pb-1">
            Arta Basha Jakupi - University of Prishtina “Hasan Prishtina”
          </p>
          <p className="pb-1">
            Avni Berisha - University of Prishtina “Hasan Prishtina”
          </p>
          <p className="pb-1">
            Bekim Sejdiu - University of Prishtina “Hasan Prishtina
          </p>
          <p className="pb-1">
            Besim Gollopeni - University of Mitrovica “Isa Boletini”
          </p>
          <p className="pb-1">
            Bujar Pira - University of Applied Sciences Ferizaj
          </p>
          <p className="pb-1">Davor Ljubas - University of Zagreb</p>
          <p className="pb-1">
            Dhuartë Hyseni - University of Prishtina “Hasan Prishtina”
          </p>
          <p className="pb-1">Driton Sylqa - University of Peja “Haxhi Zeka”</p>
          <p className="pb-1">Dukagjin Leka - University Kadri Zeka Gjilan</p>
          <p className="pb-1">
            Ercan Canhasi - University of Prizren “Ukshin Hoti”
          </p>
          <p className="pb-1">
            Florent Bunjaku - University of Prishtina “Hasan Prishtina
          </p>
          <p className="pb-1">
            Hasim Rushiti - University of Prishtina “Hasan Prishtina”
          </p>
          <p className="pb-1">
            Iliya Kereziev - University of National and World Economy
          </p>
          <p className="pb-1">Irena Žmak - University of Zagreb</p>
          <p className="pb-1">
            Izer Maksuti - University of Prizren “Ukshin Hoti”
          </p>
          <p className="pb-1">
            Jehona Shkodra - University of Prishtina “Hasan Prishtina”
          </p>
          <p className="pb-1">Jeton Raja - University Fehmi Agani Gjakova</p>
          <p className="pb-1">
            Luljeta Plakolli-Kasumi - University of Prishtina “Hasan Prishtina”
          </p>
          <p className="pb-1">
            Milote Sadiku - University of Prishtina “Hasan Prishtina”
          </p>
          <p className="pb-1">
            Mirsade Osmani - University “Isa Boletini” in Mitrovica
          </p>
          <p className="pb-1">
            Monika Sabeva - University of National and World Economy
          </p>
          <p className="pb-1">
            Nol Krasniqi - University of Prizren “Ukshin Hoti”
          </p>
          <p className="pb-1">
            Rilind Obertinca - University Fehmi Agani Gjakova
          </p>
          <p className="pb-1">
            Rrahim Sejdiu - University of Applied Sciences Ferizaj
          </p>
          <p className="pb-1">
            Shaban Buza - University of Prishtina “Hasan Prishtina
          </p>
          <p className="pb-1">
            Shemsi Morina - University of Prizren “Ukshin Hoti”
          </p>
          <p className="pb-1">
            Sindorela Doli - University Fehmi Agani Gjakova
          </p>
          <p className="pb-1">
            Suada Ajdarpasic - University of Peja “Haxhi Zeka”
          </p>
          <p className="pb-1">
            Suada Dzogovic - University of Peja “Haxhi Zeka”
          </p>
          <p className="pb-1">
            Theranda Beqiri - University of Peja “Haxhi Zeka”
          </p>
          <p className="pb-1">
            Vjosa Hajdari - University of Peja “Haxhi Zeka”
          </p>
          <p className="pb-1">
            Vlora Berisha - University of Peja “Haxhi Zeka”
          </p>
          <p className="pb-1">Xhevdet Thaqi - University Kadri Zeka Gjilan</p>
          <p className="pb-1">
            Zijadin Guri - University of Prishtina “Hasan Prishtina”
          </p>
          <p className="pb-1">
            Zirije Hasani - University of Prizren “Ukshin Hoti”
          </p>
        </div>
      </div>
    </>
  );
};

export default OrganizingCommitteeConference1;
