import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./Conferences.css";

const ConferenceLayout = ({ children }) => {
  return (
    <div className="mainWrapper">
      <Navbar />
      <main className="pt-4">
        <div className="d-flex align-items-start px-2 mainContainer">
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ConferenceLayout;
