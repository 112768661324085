import React, { useContext, useEffect, useState } from "react";
import { GetJournalUsers } from "../endpoints";
import BoardMemberItem from "../UI/BoardMemberItem";
import { UserRole, UserRoleType } from "../Enums";
import Preloader from "../UI/Preloader/Preloader";
import { useTranslation } from "react-i18next";
import { JournalContext } from "../store/JournalContext";

function EditorialBoard() {
  const [chiefEditors, setChiefEditors] = useState([]);
  const [editors, setEditors] = useState([]);
  const [coEditorsInChief, setCoEditorsInChief] = useState([]);
  const [associateEditors, setAssociateEditors] = useState([]);
  const [technicalEditors, setTechnicalEditors] = useState([]);
  const [secretariatList, setSecretariatList] = useState([]);
  const [areEditorsLoaded, setAreEditorsLoaded] = useState(false);
  const [areChiefEditorsLoaded, setAreChiefEditorsLoaded] = useState(false);
  const [areCoEditorsInChiefLoaded, setAreCoEditorsInChiefLoaded] =
    useState(false);
  const [areAssociateEditorsLoaded, setAreAssociateEditorsLoaded] =
    useState(false);
  const [areTechnicalEditorsLoaded, setAreTEchnicalEditorsLoaded] =
    useState(false);
  const [isSecretariatListLoaded, setIsSecretariatListLoaded] = useState(false);
  const { t } = useTranslation();

  const {
    showEditors,
    showEditorsInChief,
    showCoEditorsInChief,
    showAssociateEditors,
    showTechnicalEditors,
    showSecretariatList,
  } = useContext(JournalContext);

  useEffect(() => {
    GetJournalUsers([UserRole.ChiefEditor], UserRoleType.EditorInChief).then(
      (res) => {
        setChiefEditors(res.data.users);
        setAreChiefEditorsLoaded(true);
      }
    );
    GetJournalUsers([UserRole.Editor], UserRoleType.Editor).then((res) => {
      setEditors(res.data.users);
      setAreEditorsLoaded(true);
    });
    GetJournalUsers([UserRole.ChiefEditor], UserRoleType.CoEditorInChief).then(
      (res) => {
        setCoEditorsInChief(res.data.users);
        setAreCoEditorsInChiefLoaded(true);
      }
    );
    GetJournalUsers([UserRole.Editor], UserRoleType.AssociateEditor).then(
      (res) => {
        setAssociateEditors(res.data.users);
        setAreAssociateEditorsLoaded(true);
      }
    );
    GetJournalUsers([UserRole.Editor], UserRoleType.TechnicalEditor).then(
      (res) => {
        setTechnicalEditors(res.data.users);
        setAreTEchnicalEditorsLoaded(true);
      }
    );
    GetJournalUsers([UserRole.Editor], UserRoleType.Secretariat).then((res) => {
      setSecretariatList(res.data.users);
      setIsSecretariatListLoaded(true);
    });
  }, []);

  return (
    <div className="card boardContainer">
      {showEditorsInChief && (
        <div>
          <h2 className="boardHeader">
            {chiefEditors.length > 1 ? t("chiefEditors") : t("chiefEditor")}
          </h2>
          <div className="innerDiv">
            <Preloader isReady={areChiefEditorsLoaded}>
              {chiefEditors.map((user) => {
                return <BoardMemberItem user={user} key={user.id} />;
              })}
            </Preloader>
          </div>
        </div>
      )}
      {showCoEditorsInChief && (
        <div>
          <h2 className="boardHeader">
            {coEditorsInChief.length > 1
              ? "Co-Editors-in-Chief"
              : "Co-Editor-in-Chief"}
          </h2>
          <div className="innerDiv">
            <Preloader isReady={areCoEditorsInChiefLoaded}>
              {coEditorsInChief.map((user) => {
                return <BoardMemberItem user={user} key={user.id} />;
              })}
            </Preloader>
          </div>
        </div>
      )}
      {showEditors && (
        <div>
          <h2 className="boardHeader">
            {editors.length > 1 ? t("editors") : t("editor")}
          </h2>
          <div className="innerDiv">
            <Preloader isReady={areEditorsLoaded}>
              {editors.map((user) => {
                return <BoardMemberItem user={user} key={user.id} />;
              })}
            </Preloader>
          </div>
        </div>
      )}
      {showAssociateEditors && (
        <div>
          <h2 className="boardHeader">
            {associateEditors.length > 1
              ? t("associateEditors")
              : t("associateEditor")}
          </h2>
          <div className="innerDiv">
            <Preloader isReady={areAssociateEditorsLoaded}>
              {associateEditors.map((user) => {
                return <BoardMemberItem user={user} key={user.id} />;
              })}
            </Preloader>
          </div>
        </div>
      )}
      {showTechnicalEditors && (
        <div>
          <h2 className="boardHeader">
            {technicalEditors.length > 1
              ? t("technicalEditors")
              : t("technicalEditor")}
          </h2>
          <div className="innerDiv">
            <Preloader isReady={areTechnicalEditorsLoaded}>
              {technicalEditors.map((user) => {
                return <BoardMemberItem user={user} key={user.id} />;
              })}
            </Preloader>
          </div>
        </div>
      )}
      {showSecretariatList && (
        <div>
          <h2 className="boardHeader">Secretariat List</h2>
          <div className="innerDiv">
            <Preloader isReady={isSecretariatListLoaded}>
              {secretariatList.map((user) => {
                return <BoardMemberItem user={user} key={user.id} />;
              })}
            </Preloader>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditorialBoard;
