import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomizableLineChart = ({ data, color, datakey }) => {
  const currentMonth = new Date().getMonth();
  for (let i = currentMonth + 1; i < 12; i++) {
    data[i].views = null;
  }

  const CustomTickFormatter = (name) => {
    const maxLength = 3;
    if (name.length > maxLength) {
      return name.slice(0, maxLength);
    }
    return name;
  };

  return (
    <ResponsiveContainer width="95%" height={200}>
      <LineChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickFormatter={CustomTickFormatter} />
        <YAxis />
        <Tooltip
          wrapperStyle={{
            width: "fit-content",
            backgroundColor: "#ccc",
            textTransform: "capitalize",
          }}
        />
        <Line
          type="monotone"
          dataKey={datakey}
          stroke={color}
          strokeDasharray="5 5"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomizableLineChart;
