import React from "react";
import { Link } from "react-router-dom";
import file from "../../../Assets/Files/Agenda for the conference_ UHZ_13.04.22.pdf";

const ProgramConference4 = () => {
  return (
    <>
      <div className="conference-program">
        <h2 className="boardHeader">Conference Program</h2>
        <div className="py-2 px-3 mt-3">
          <p>
            <Link to={file} target="_blank">
              Conference programs
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ProgramConference4;
