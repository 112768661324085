import React from "react";
import Conference4 from "./Conference4";

const AboutConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">About Us</h2>
      <div className="p-2 mt-3">
        <p className="text-center mb-3">
          <strong
            style={{ fontSize: "16px", lineHeight: "21px", color: "#707070" }}
          >
            INTERNATIONAL CONFERENCE ON NEW ACHIEVEMENTS IN SCIENCE, TECHNOLOGY
            AND ARTS
          </strong>
        </p>
        <p className="text-center mb-4">
          <strong
            style={{ fontSize: "16px", lineHeight: "21px", color: "#707070" }}
          >
            ICNA-STA
          </strong>
        </p>
        <div className="px-3">
          <p className="textJustify">
            <span style={{ color: " #800000" }}>
              <strong>&nbsp; 14 – 15 April, 2022</strong>
            </span>
          </p>
          <p className="textJustify">
            <strong>Venue, University of Haxhi Zeka in Peja</strong>
          </p>
          <p>
            Within the framework of the project “
            <strong>
              Enhancing Research Culture in Higher Education in Kosovo
              (ResearchCult)”,
            </strong>
            supported by Erasmus +, with funds from European Union, the
            University “Haxhi Zeka” of Peja, is organizing a two-day
            International scientific conference on: “
            <strong>
              International Conference on New Achievements in Science,
              Technology and Arts” – ICNA-STA
            </strong>
            . The conference is co-organized with a number of local and
            international universities and higher education institutions, as
            represented below in the relevant organization and scientific
            structures of the conference.
          </p>
          <p>
            The conference aims to bring together the representatives of the
            higher education institutions (HEIs), research institutions, public
            institutions, businesses and other stakeholders locally and
            internationally, to exchange experiences, share best practices in
            various scientific areas. In addition, the conference also offers
            opportunities for researchers, academicians and industry experts to
            meet and interact with local and international participants on how
            to best:
          </p>
          <ul>
            <li>
              utilize and enhancing academic learning by integrating research
              and innovation;
            </li>
            <li>
              enhance cooperation between the academia and public and private
              institutions through usage of research and innovation outcomes;
            </li>
          </ul>
          <p>
            More specifically the main objectives of the Conference will be:
          </p>
          <ul>
            <li>
              to provide a forum for discussion about the latest scientific
              contribution in various areas of science;
            </li>
            <li>
              to focus on the impact of the research and innovation in the
              scientific fields covered;
            </li>
            <li>
              to discuss and compare the experiences in all scientific areas
              covered in this conference;
            </li>
          </ul>
          <p>
            In particular,{" "}
            <strong>
              the post Covid- 19 pandemic conditions –challenges and
              opportunities – affecting human rights
            </strong>{" "}
            will be discussed and analyzed from the perspective of
            transformation and change.
          </p>
          <p className="textJustify" style={{ marginTop: "15px" }}>
            <strong>Format of the event:</strong>
          </p>
          <p className="textJustify">The two day event will consist of:</p>
          <p className="textJustify">– plenary sessions</p>
          <p className="textJustify">– thematic sessions</p>
          <p className="textJustify">– poster presentations</p>
          <p className="textJustify">– workshops</p>
          <p className="textJustify">
            The conference is anticipated{" "}
            <strong>to be held through the physical presence</strong> in the
            premises of the University “Haxhi Zeka” in Peja. However, the
            organization of the on-person conference is pending upon the
            conditions and recommendations of the relevant institutions related
            to the Covid -19 and governmental measures around March-April 2022.
            All participating candidates will be informed on time in case any
            measures will take place around the time of conference. Throughout
            the conference, sufficient time will be reserved for comments and
            questions from participants to allow them to contribute to
            discussions.
          </p>
          <p>
            <em>
              <strong>The language of the conference will be English.</strong>
            </em>
          </p>
          <h4
            className="textJustify"
            style={{
              color: "#0f358e",
              marginTop: "15px",
            }}
          >
            Deadlines
          </h4>
          <div className="d-flex flex-column" style={{ gap: "8px" }}>
            <p>
              <strong>Abstract submission: 13/03/2022</strong>
            </p>
            <p>
              Notification of acceptance of abstract: within 10 days of the
              submission date (<strong>20/03/2022</strong>)
            </p>
            <p>
              <strong>Full paper submission: 30/03/2022</strong>
            </p>
            <p>
              Notification of acceptance of full paper: within 10 days of the
              submission date (<strong>10/04/2022</strong>)
            </p>
            <p>
              <strong>Poster presentation submission: 20/03/2022</strong>
            </p>
            <p>
              Notification of acceptance of poster presentation: within 7 days
              of the submission date (<strong>27/03/2022</strong>)
            </p>
            <p>
              <strong>
                Final registration on the conference (for oral presentation and
                poster presentation: 5/04/2022
              </strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutConference4;
