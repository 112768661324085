import React from "react";

function HamburgerMenu({ setShowMenu, showMenu }) {
  return (
    <button
      className={`hamburgerMenu ${showMenu && "active"}`}
      onClick={() => setShowMenu((prev) => !prev)}
      aria-controls="primary-navigation"
    >
      <svg viewBox="0 0 100 100" width="25">
        <rect
          className="line top"
          width="80"
          height="10"
          x="10"
          y="25"
          rx="5"
        ></rect>
        <rect
          className="line middle"
          width="80"
          height="10"
          x="10"
          y="45"
          rx="5"
        ></rect>
        <rect
          className="line bottom"
          width="80"
          height="10"
          x="10"
          y="65"
          rx="5"
        ></rect>
      </svg>
    </button>
  );
}

export default HamburgerMenu;
