import React, { useContext } from "react";
import { JournalContext } from "../store/JournalContext";
import { useTranslation } from "react-i18next";

function Aims() {
  const { journalAims } = useContext(JournalContext);

  const { t } = useTranslation();

  return (
    <div className="card">
      <h2 className="boardHeader">{t("aims")}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: journalAims?.value }}
        className="innerDiv"
      ></div>
    </div>
  );
}

export default Aims;
