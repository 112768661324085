import React, { useContext, useEffect, useState } from "react";
import { SearchContext } from "../store/SearchContext";
import { VscSearchStop } from "react-icons/vsc";
import ArticleItem from "../components/HomepageComponents/ArticleItem";
import Preloader from "../UI/Preloader/Preloader";
import { GetArticlesListWithParams } from "../endpoints";
import PagingButtons from "../UI/PagingButtons/PagingButtons";
import { useTranslation } from "react-i18next";

function EmptyResult() {
  const { t } = useTranslation();
  return (
    <div className="emptyResult">
      <VscSearchStop className="emptyResultIcon" />
      <p>{t("noArticleMatchYourSearch")}.</p>
      <p>{t("tryDifferentCombinationOfParameters")}.</p>
    </div>
  );
}

function SearchArticles() {
  const { t } = useTranslation();

  const {
    searchResult,
    isSearchCompleted,
    searchParams,
    setSearchResult,
    setIsSearchCompleted,
    shouldTriggerSearch,
    newPageNumber,
    setNewPageNumber,
  } = useContext(SearchContext);
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    setIsSearchCompleted(false);
    GetArticlesListWithParams({
      ...searchParams,
      pageNumber: newPageNumber,
    }).then((res) => {
      setSearchResult(res.data.articles);
      setIsSearchCompleted(true);
    });
  }, [shouldTriggerSearch, newPageNumber]);

  return (
    <div className="card mt-1">
      <h2 className="boardHeader">
        {t("searchResults")} ({searchResult.totalCount}):
      </h2>
      <Preloader isReady={isSearchCompleted} minHeight="5vh">
        {searchResult.items?.length == 0 && <EmptyResult />}
        {searchResult.items?.map((article, i) => {
          return <ArticleItem article={article} i={i} key={article.id} />;
        })}
        {searchResult.items?.length != 0 && (
          <PagingButtons
            totalPages={searchResult.totalPages}
            setNewPageNumber={setNewPageNumber}
            hasPreviousPages={searchResult.hasPreviousPage}
            hasNextPages={searchResult.hasNextPage}
            pageNumber={searchResult.pageNumber}
          />
        )}
      </Preloader>
    </div>
  );
}

export default SearchArticles;
