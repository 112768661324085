import React from "react";
import { useTranslation } from "react-i18next";

function EmptyResponse({ message = "There is no data to display here yet" }) {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-center flex-column emptyResponse">
      <i className="bi bi-journal-x"></i>
      <p>{t("emptyResponse")}</p>
    </div>
  );
}

export default EmptyResponse;
