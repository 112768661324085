import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import SidebarRight from "../components/HomepageComponents/SidebarRight";
import MainJournalFilter from "../components/MainJournalFilter/MainJournalFilter";
import { SearchContext } from "../store/SearchContext";
import SidearLeft from "../components/JournalMenu/SidearLeft";

function RootLayout() {
  const { setShowAdvancedSearch } = useContext(SearchContext);
  const location = useLocation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    if (location.pathname.includes("search")) {
      setShowAdvancedSearch(true);
    } else {
      setShowAdvancedSearch(false);
    }
  }, [location, setShowAdvancedSearch]);


  return (
    <div className="mainWrapper">
      <Navbar />
      <MainJournalFilter />
      <main>
        <div className="mt-4 d-flex align-items-start px-2 mainContainer">
          <SidearLeft />
          <div style={{ flex: 4 }}>
            <Outlet />
          </div>
          <SidebarRight />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default RootLayout;
