import React, { useState } from "react";
import HamburgerMenu from "../../../UI/HamburgerMenu";
import { Link, NavLink, useNavigate } from "react-router-dom";

const ConferenceMenu1 = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="positionSticky sidebarLeftContainer">
      <HamburgerMenu setShowMenu={setShowMenu} showMenu={showMenu} />
      <div className={`sidebarLeft ${showMenu && "showMenu"}`}>
        <div>
          <div
            className="card p-0 mb-1"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
          >
            <h2
              onClick={() => navigate("/")}
              className="boardHeader m-0 backLink"
            >
              <i className="bi bi-arrow-left me-1"></i>
              Back
            </h2>
          </div>
          <div className="conferencesMenuContainer">
            <nav className="conferencesMenu">
              <NavLink to={"/conference-1/about"}>About the Conference</NavLink>
              <NavLink to={"/conference-1/keynote-speakers"}>
                Keynote Speakers
              </NavLink>
              <NavLink to={"/conference-1/organizing-committee"}>
                Organizing Committee
              </NavLink>
              <NavLink to={"/conference-1/scientific-committee"}>
                Scientific Committee
              </NavLink>
              <NavLink to={"/conference-1/conference-thematic-topics"}>
                Conference Thematic Topics
              </NavLink>
              <NavLink to={"/conference-1/registration"}>
                Registration for the Conference
              </NavLink>
              <NavLink to={"/conference-1/conference-program"}>
                Conference Program
              </NavLink>
              <NavLink to={"/conference-1/abstract-submission"}>
                Abstract Submission
              </NavLink>
              <NavLink to={"/conference-1/book-of-abstracts"}>
                Book of Abstracts
              </NavLink>
              <NavLink to={"/conference-1/poster-presentation"}>
                Poster Presentation
              </NavLink>
              <NavLink to={"/conference-1/supporting-journals"}>
                Supporting Scientific Journals
              </NavLink>
              <NavLink to={"/conference-1/sponsorship"}>Sponsorship</NavLink>
              <NavLink to={"/conference-1/venue"}>Venue</NavLink>
              <NavLink to={"/conference-1/secretariat-contact"}>
                Secretariat & Contact Information
              </NavLink>
              <NavLink to={"/conference-1/organizing-partners"}>
                Organizing Partners
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceMenu1;
