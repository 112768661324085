import React from "react";

const KeynoteSpeakersConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">KEYNOTE SPEAKERS</h2>
      <div className="py-2 mt-3 px-3">
        <p>
          <span lang="SQ">
            Nobel&nbsp;Prize Laureate&nbsp;Prof. Dan Shechtman, Technion, Haifa
            - Israel, and ISU, Ames, Iowa - USA
          </span>
        </p>
        <p>Prof. Andrew Collins, University of Oslo, Norway</p>
        <p>
          Prof.&nbsp;Mária Dusinšká, Norwegian Institute for Air Research
          (NILU), Norway
        </p>
        <p>
          A.Prof. Johannes&nbsp; Novak, University of Veterinary Medicine,
          Vienna, &nbsp;Austria
        </p>
        <p>
          Dr. Igor Belic,&nbsp;Institute for Forensics and Information
          Technology (iFIT), Slovenia
        </p>
        <p>
          <span lang="SQ">
            Dr Eleanor Pritchard, FHEA. Social Sciences Division, University of
            Oxford, UK
            <br />
          </span>
        </p>
      </div>
    </>
  );
};

export default KeynoteSpeakersConference1;
