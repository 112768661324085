import React, { useState } from "react";
import ConferenceLayout from "../ConferenceLayout";
import { GUID } from "../../../JournalSettings";
import { JournalId } from "../../../Enums";
import ErrorPathing from "../../ErrorPathing/ErrorPathing";
import ConferenceMenu4 from "../ConferenceMenu/ConferenceMenu4";
import { Outlet } from "react-router-dom";

const Conference4 = () => {
  if (GUID !== JournalId.ICNA_STA) {
    return <ErrorPathing></ErrorPathing>;
  }

  return (
    <ConferenceLayout>
      <ConferenceMenu4 />
      <div className="d-flex flex-column" style={{ flex: 1 }}>
        <div className="card p-0 mb-1" style={{ flex: 4 }}>
          <h2 className="boardHeader m-0">SCIENTIFIC CONFERENCE</h2>
        </div>
        <div className="card" style={{ flex: 4, lineHeight: "21px" }}>
          <Outlet />
        </div>
      </div>
    </ConferenceLayout>
  );
};

export default Conference4;
