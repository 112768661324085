import React from "react";

const SecretariatContactConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">SECRETARIAT & CONTACT INFORMATION</h2>
      <div className="py-2 mt-3 px-3 book-abstracts">
        <div className="mb-3">
          <h5 className="mb-2">
            <span className="headerColor">
              <strong>TECHNICAL SECRETARIAT:</strong>
            </span>
          </h5>
          <p className="pb-1">
            Fitim Seferi, University of Prishtina “Hasan Prishtina
          </p>
          <p className="pb-1">
            Blerta Dibrani, University of Prishtina “Hasan Prishtina
          </p>
          <p className="pb-1">
            Hajrullah Hajrullahu, University of Prishtina “Hasan Prishtina
          </p>
          <p className="pb-1">
            Flamur Abazaj, International Business College Mitrovica
          </p>
          <p className="pb-1">
            Damir Gashi, International Business College Mitrovica
          </p>
        </div>
        <div className="mb-3">
          <h5 className="mb-2">
            <span className="headerColor">
              <strong>Contacts</strong>
            </span>
          </h5>
          <p className="pb-1">
            For abstract and poster presentation submission, and registration
            through website of conference:
          </p>
          <p className="pb-1">
            <a href="/conference-1/about" target="_blank">
              Website
            </a>
          </p>
          <p className="pb-1">
            <strong>Email:</strong>{" "}
            <a href="mailto:conference.researchcult@uni-pr.edu">
              conference.researchcult@uni-pr.edu
            </a>
          </p>
          <p>
            <strong>Phone:</strong> +383 38 247 069 ex. 103
          </p>
        </div>
      </div>
    </>
  );
};

export default SecretariatContactConference1;
