import React from "react";

const SponsorshipConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">SPONSORSHIP</h2>
      <div className="py-2 px-3 mt-3 sponsorship">
        <p>Possibility to use financial support from CEEPUS Program.</p>
        <div className="mb-4">
          <p className="my-2">
            <strong>CEEPUS PROGRAM</strong>
          </p>
          <p>
            CEEPUS is the short form for Central European Exchange Program for
            University Studies and is a multilateral University exchange program
            in the extended Danube region based on an international Agreement.
          </p>
        </div>
        <div className="mb-4">
          <p className="my-2">
            <strong>List of the participating countries:</strong>
          </p>
          <p>
            Current countries: Albania, Austria, Bosnia and Herzegovina,
            Bulgaria, Croatia, the Czech Republic, Hungary, North Macedonia,
            Moldova, Montenegro, Poland, Romania, Serbia, the Slovak Republic
            and Slovenia, Kosovo.
          </p>
        </div>
        <div className="mb-4">
          <p>
            <strong>Am I eligible for an CEEPUS application?</strong>
          </p>
          <p>
            Eligible are students and faculty members of a CEEPUS University
            that are also citizens of a CEEPUS member state. If you have a
            different citizenship but are enrolled at a CEEPUS university, you
            might also be eligible. In that case, please ask your National
            CEEPUS Office for restrictions and further information.
          </p>
          <p className="mt-2">
            For Kosovo:{" "}
            <a href="mailto:Shpresa.mehmeti@rks-gov.net">
              Shpresa.mehmeti@rks-gov.net
            </a>
          </p>
        </div>
        <div className="mb-4">
          <p className="my-2">
            <strong>Am I eligible as a freemover?</strong>
          </p>
          <p>
            If your home university or institute is not listed in a network you
            can also apply as a freemover in any CEEPUS country. Please note,
            that there is only a limited amount of freemover grants available.
          </p>
          <p>
            The interested applicants apply online at CEEPUS webpage:{" "}
            <a href="https://www.ceepus.info/content/apply" target="_blank">
              https://www.ceepus.info/content/apply
            </a>
          </p>
          <p>
            <a href="https://www.ceepus.info/signin#nbb" target="_blank">
              https://www.ceepus.info/signin#nbb
            </a>
          </p>
          <p>
            After signing in, follow the instructions in our “Mobility Wizard”
            which will guide you through the online application process.
          </p>
        </div>
        <div className="mb-4">
          <p className="my-2">
            <strong>Information for incomings:</strong>
          </p>
          <p>Teachers should stay at least of five days in Kosovo!</p>
          <p>
            The accommodation is provided in the Dormitory (the area for
            teachers) in Prishtina.
          </p>
          <p>
            Due to administrative procedures, payment may take time and will be
            made upon return via bank transfer. The financial form will be
            provided by NCO Kosovo.
          </p>
          <p>Please contact NCO in Kosovo for more detailed information.</p>{" "}
        </div>
      </div>
    </>
  );
};

export default SponsorshipConference4;
