import React from "react";
import { useNavigate } from "react-router-dom";

function RouteInactiveChecker({ isActive, children }) {
  const navigate = useNavigate()

if(!isActive) {
  throw new Error()
}
  return (
    <>
    {children}
    </>
  );
}

export default RouteInactiveChecker;
