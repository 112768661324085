import React, { useContext } from "react";
import "./MainJournalFilter.css";
import Input from "../Inputs/Input/Input";
import Select from "../Inputs/Select/Select";
import {
  GetArticleTypesList,
  GetArticlesListWithParams,
  GetSectionsSelectList,
} from "../../endpoints";
import { SearchContext } from "../../store/SearchContext";
import { Link } from "react-router-dom";
import AdvancedSearch from "./AdvancedSearch";
import { useTranslation } from "react-i18next";

function MainJournalFilter() {
  const { t } = useTranslation();
  const { setShowAdvancedSearch, setShouldTriggerSearch, setNewPageNumber } =
    useContext(SearchContext);

  return (
    <form
      className="d-flex align-items-center  mainSearchFrom justify-content-center"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div
        className="positionSticky sidebarLeftContainer journalMenuContainer"
        style={{ flex: "unset" }}
      ></div>
      <div className="searchDiv">
        <span>{t("searchArticles")}:</span>
        <div className="mainInputWrapper" style={{ flex: 1 }}>
          <div className="d-flex align-items-center inputContainer px-1">
            <Input
              id={"keyword"}
              name={"keywordsSearch"}
              placeholder={t("title/keyword/author/affiliation/email")}
              triggerContext={true}
            />
          </div>
          <AdvancedSearch />
        </div>
        <div className="mainJournalFilterButtons d-flex justify-content-center">
          <Link
            to="/search-articles"
            onClick={() => {
              setShouldTriggerSearch((prev) => !prev);
              setNewPageNumber(1);
            }}
          >
            <button type="button" className="btn mainBtn">
              {t("search")}
            </button>
          </Link>
          <button
            type="button"
            className="btn btn-outline-success"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => setShowAdvancedSearch((prev) => !prev)}
          >
            {t("advancedsearch")}
          </button>
        </div>
      </div>
      <div className="sidebarRight positionSticky"></div>
    </form>
  );
}

export default MainJournalFilter;
