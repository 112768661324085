import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { JournalContext } from "../../store/JournalContext";

function ArticleItem({ article, i }) {
  const publishedDate = new Date(article.publishedDate);
  const navigate = useNavigate();
  const { hasSections, hasVolumes, hasArticleTypes } =
    useContext(JournalContext);

  const { t } = useTranslation();

  return (
    <article
      className="articleItem my-1 innerDiv"
      key={i + article.id}
      onClick={() => navigate(`/article/${article.id}`)}
    >
      <h2 className="title">{article.title}</h2>
      <p className="authors">
        {t("by")}:
        {article?.articleAuthors?.map((author, i) => (
          <span key={author.id}>
            <FaUserCircle
              style={{
                color: "var(--main800)",
                verticalAlign: "midddle",
                aspectRatio: "1/1",
                fontSize: "0.9rem",
                marginLeft: "3px",
                marginRight: "2px",
              }}
            />
            {author.firstName.charAt(0).toUpperCase() +
              author.firstName.slice(1)}{" "}
            {author.middleName.charAt(0).toUpperCase() +
              author.middleName.slice(1)}{" "}
            {author.lastName.charAt(0).toUpperCase() + author.lastName.slice(1)}
            {i + 1 !== article.articleAuthors.length && ", "}
          </span>
        ))}
      </p>
      <span className="publication">
        <em>{hasSections?.isActive && article.journalSection + ", "}</em>
        {/* {hasArticleTypes.isActive && article.articleType + ", "}{" "} */}
        {hasArticleTypes?.isActive &&
          article.articleType &&
          `${article.articleType}, `}
        {hasVolumes?.isActive && article.volume && article.volume + ", "}
        <b>{publishedDate.getFullYear()}</b>, {article.numberOfPages}
      </span>
      <p className="abstract">
        <span>{t("abstract")}: </span>
        {article.abstract}
      </p>
    </article>
  );
}

export default ArticleItem;
