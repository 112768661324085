import React from "react";

const ComitteesConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">CONFERENCE COMMITTEES</h2>
      <div className="py-2 px-3 mt-3 committees-conference">
        <h5
          className="textJustify"
          style={{
            color: "rgb(15, 53, 142)",
          }}
        >
          <strong>Key Speakers</strong>
        </h5>
        <div className="mx-2 key-speakers">
          <p>
            <strong>Fiorenza Deriu</strong>, <em>University of Sapienza</em>
          </p>
          <p>
            <strong>Diana Kopeva,</strong>{" "}
            <em>University of National World Economy</em>
          </p>
          <p>
            <strong>Lidija Curkovic</strong>, <em>University of Zagreb</em>
          </p>
          <p>
            <strong>Nikša Alfirević</strong>, University of Split &#8211;
            Faculty of Economics, Business and Tourism, Split, Croatia /{" "}
            <em>Editor, Journal of Contemporary Management Issues</em>
          </p>
          <p>
            <strong>Krzysztof Szymanski</strong>, SGH Warsaw School of
            Economics, Poland
          </p>
          <p>
            <strong>Besnik Krasniqi, </strong>
            <em>University of Prishtina</em>
          </p>
          <p>
            <strong>Mihone Kerolli</strong>,
            <em> International Business College Mitrovica</em>
          </p>
        </div>
        <h6
          className="textJustify"
          style={{
            color: "rgb(15, 53, 142)",
            margin: "15px 0 10px 0",
          }}
        >
          <strong>Organizing Committee</strong>
        </h6>
        <h6
          className="textJustify"
          style={{
            color: "rgb(15, 53, 142)",
          }}
        >
          <strong>Chair of the Conference </strong>
        </h6>
        <div className="mx-2 chair-of-conference">
          <p>
            <strong>Alma Shehu &#8211; Lokaj</strong>,{" "}
            <em>University of “Haxhi Zeka” in Peja</em>
          </p>
          <p>
            <strong>Co-Chair of the Conference</strong>
          </p>
          <p>
            <strong>Bujar Gallopeni</strong>,{" "}
            <em>International Business College Mitrovica</em>
          </p>
        </div>{" "}
        <p className="mt-3">
          <strong>Members:</strong>
        </p>
        <table width="70%">
          <tbody>
            <tr>
              <td width="24%">
                <strong>Abedin Sadria</strong>
              </td>
              <td width="49%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Edmond Beqiri</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Agron Hajdari</strong>
              </td>
              <td width="49%"> International Business College Mitrovica</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Agron Rustemi</strong>
              </td>
              <td width="49%"> South East European University</td>
              <td width="7%"> Skopje</td>
              <td width="16%"> North Macedonia</td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Arbër Hyseni</strong>
              </td>
              <td width="49%"> University of Mitrovica “Isa Boletini”</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Baki Koleci</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Bashkim Çerkini</strong>
              </td>
              <td width="49%"> University of Applied Sciences in Ferizaj</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Besfort Ahmeti</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Betim Berisha</strong>
              </td>
              <td width="49%"> ”Kadri Zeka” in Gjilan</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Betim Berisha</strong>
              </td>
              <td width="49%"> University of Prizren “Ukshin Hoti” Prizren</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Bleranda Xhema</strong>
              </td>
              <td width="49%"> University of Prishtina Hasan Prishtina</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Cecilia Nocella</strong>
              </td>
              <td width="49%"> Sapienza University of Rome</td>
              <td width="7%"> Italy</td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Damir Gashi</strong>
              </td>
              <td width="49%"> International Business College Mitrovica</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Davor Ljubas</strong>
              </td>
              <td width="49%"> University of Zagreb</td>
              <td width="7%"> Croatia</td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Denis Spahija</strong>
              </td>
              <td width="49%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Donjeta Morina</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Edmond Beqiri</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Edona Dragaj</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Elvis Elezaj</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Fakije Zejnullahu</strong>
              </td>
              <td width="49%"> University of Applied Sciences in Ferizaj</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Fisnik Morina</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Fitim Seferi</strong>
              </td>
              <td width="49%"> University of Prishtina Hasan Prishtina</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Florim Gallopeni</strong>
              </td>
              <td width="49%">
                 Institute for Research Education and Social Development
              </td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Giovanna Gianturco</strong>
              </td>
              <td width="49%"> Sapienza University of Rome</td>
              <td width="7%"> Italy</td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Halit Shabani</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Ilirjana Geci</strong>
              </td>
              <td width="49%"> AAB College</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Irena Žmak</strong>
              </td>
              <td width="49%"> University of Zagreb</td>
              <td width="7%"> Croatia</td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Ismajl Mehmeti</strong>
              </td>
              <td width="49%"> University of Applied Sciences in Ferizaj</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Luljete Berisha</strong>
              </td>
              <td width="49%"> ”Kadri Zeka” in Gjilan</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Luljete Berisha</strong>
              </td>
              <td width="49%"> University of Prizren “Ukshin Hoti” Prizren</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Maria Teresa Chiaranti</strong>
              </td>
              <td width="49%"> Sapienza University of Rome</td>
              <td width="7%"> Italy</td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Mariella Nocenzi</strong>
              </td>
              <td width="49%"> Sapienza University of Rome</td>
              <td width="7%"> Italy</td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Nikoaly Sterev</strong>
              </td>
              <td width="49%"> University of National and World Economy</td>
              <td width="7%"> Sofia</td>
              <td width="16%"> Bulgaria</td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Rasim Zuferi</strong>
              </td>
              <td width="49%"> South East European University</td>
              <td width="7%"> Skopje</td>
              <td width="16%"> North Macedonia</td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Rifat Morina</strong>
              </td>
              <td width="49%"> University of Prizren “Ukshin Hoti” Prizren</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Rolanda Mula</strong>
              </td>
              <td width="49%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Rrahim Sejdiu</strong>
              </td>
              <td width="49%"> University of Applied Sciences in Ferizaj</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Sabiha Shala</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Selim Daku</strong>
              </td>
              <td width="49%"> University</td>
              <td colspan="2" width="24%">
                 ”Kadri Zeka” in Gjilan
              </td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Shkelqesa Smajli</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Suada Hajdarpasic</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Theranda Beqiri</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Uranela Demaj</strong>
              </td>
              <td width="49%"> AAB College</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Valdrin Misini</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Vehbi Ramaj</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Vjosa Hajdari</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Vjosa Hajdari</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Vlora Berisha</strong>
              </td>
              <td width="49%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="24%">
                <strong>Xhemal Zenuni</strong>
              </td>
              <td width="49%"> South East European University</td>
              <td width="7%"> Skopje</td>
              <td width="16%"> North Macedonia</td>
            </tr>
          </tbody>
        </table>
        <p className="mt-3">
          <strong>Scientific committee:</strong>
        </p>
        <table width="70%">
          <tbody>
            <tr>
              <td width="30%">
                <strong>Adrian Besimi</strong>
              </td>
              <td width="40%"> South East European University</td>
              <td width="7%"> Skopje</td>
              <td width="20%"> North Macedonia</td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Agim Rexhepi</strong>
              </td>
              <td width="40%"> University of Prishtina”Hasan Prishtina”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Armand Krasniqi</strong>
              </td>
              <td width="40%"> University “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Agron Bajraktari</strong>
              </td>
              <td width="40%"> University of Applied Sciences in Ferizaj</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Aida Alla</strong>
              </td>
              <td width="40%"> AAB College</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Aida Goga</strong>
              </td>
              <td width="40%"> University of Tirana</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Alush Musaj</strong>
              </td>
              <td width="40%"> University of Mitrovica “Isa Boletini”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Ani Arutunyan</strong>
              </td>
              <td width="40%"> University of National and World Economy</td>
              <td width="7%"> Sofia</td>
              <td width="20%"> Bulgaria</td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Antigona Dervishi Ukehaxhaj</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Arben Mehmeti</strong>
              </td>
              <td width="40%"> University of Prishtina”Hasan Prishtina”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Arben Sahiti</strong>
              </td>
              <td width="40%"> University” Kadri Zeka” in Gjilan</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Arbnor Pajaziti</strong>
              </td>
              <td width="40%"> University of Prishtina”Hasan Prishtina”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Artan Nimani</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Avni Hajdari</strong>
              </td>
              <td width="40%"> University of Prishtina”Hasan Prishtina”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Bajram Kosumi</strong>
              </td>
              <td width="40%"> University” Kadri Zeka” in Gjilan</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Baki Koleci</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Bardh Rugova</strong>
              </td>
              <td width="40%"> University of Prishtina”Hasan Prishtina”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Behxhet Shala</strong>
              </td>
              <td width="40%"> University of Mitrovica “Isa Boletini”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Bekim Berisha</strong>
              </td>
              <td width="40%"> University of Prizren “Ukshin Hoti” Prizren</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Besa Arifi</strong>
              </td>
              <td width="40%"> South East European University</td>
              <td width="7%"> Skopje</td>
              <td width="20%"> North Macedonia</td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Besim Gollopeni</strong>
              </td>
              <td width="40%"> University of Mitrovica “Isa Boletini”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Brunilda Zenelaga</strong>
              </td>
              <td width="40%"> University of Tirana</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Bruno Botta</strong>
              </td>
              <td width="40%"> Sapienza University of Rome</td>
              <td width="7%"> Italy</td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Bujar Pira</strong>
              </td>
              <td width="40%"> University of Applied Sciences in Ferizaj</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Dardan Kocinaj</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Diana Kopeva</strong>
              </td>
              <td width="40%"> University of National and World Economy</td>
              <td width="7%"> Sofia</td>
              <td width="20%"> Bulgaria</td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Drita Kadriu</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Driton Vela</strong>
              </td>
              <td width="40%"> University of Prishtina”Hasan Prishtina”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Dukagjin Leka</strong>
              </td>
              <td width="40%"> University” Kadri Zeka” in Gjilan</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Edmond Beqiri</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Elton Bahtiri</strong>
              </td>
              <td width="40%"> University of Prishtina”Hasan Prishtina”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Fabio Sciarrino</strong>
              </td>
              <td width="40%"> Sapienza University of Rome</td>
              <td width="7%"> Italy</td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Faton Hoxha</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Fiorenza Deriu</strong>
              </td>
              <td width="40%"> Sapienza University of Rome</td>
              <td width="7%"> Italy</td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Fisnik Morina</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Florim Gallopeni</strong>
              </td>
              <td width="40%">
                 Institute for Research Education and Social Development
              </td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Florin Peci</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Hajrullah Latifi</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Halit Shabani</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Haxhi Kamberi</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Husnija Bibuljica</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Hyrije Abazi</strong>
              </td>
              <td width="40%"> South East European University</td>
              <td width="7%"> Skopje</td>
              <td width="20%"> North Macedonia</td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Ibish Mazreku</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Idriz Berisha</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Ilmi Hoxha</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Ismet Hajdari</strong>
              </td>
              <td width="40%"> International Business College Mitrovica</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Jelena Djokic</strong>
              </td>
              <td width="40%"> International Business College Mitrovica</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Kastriot Dërmaku</strong>
              </td>
              <td width="40%"> University” Kadri Zeka” in Gjilan</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Kimete Canaj</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Kreshnike Dedushi</strong>
              </td>
              <td width="40%"> University of Prishtina”Hasan Prishtina”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Laura Naka</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Lidija Ćurković</strong>
              </td>
              <td width="40%"> University of Zagreb</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Lulzim Zeneli</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Memet Memeti</strong>
              </td>
              <td width="40%"> South East European University</td>
              <td width="7%"> Skopje</td>
              <td width="20%"> North Macedonia</td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Mirsade Osmani</strong>
              </td>
              <td width="40%"> University of Mitrovica “Isa Boletini”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Mihone Kerolli Mustafa</strong>
              </td>
              <td width="40%"> International Business College Mitrovica</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Monika Sabeva</strong>
              </td>
              <td width="40%"> University of National and World Economy</td>
              <td width="7%"> Sofia</td>
              <td width="20%"> Bulgaria</td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Nazli Tyfekci</strong>
              </td>
              <td width="40%"> University of Gjakova “Fehmi Agani”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Nikoaly Sterev</strong>
              </td>
              <td width="40%"> University of National and World Economy</td>
              <td width="7%"> Sofia</td>
              <td width="20%"> Bulgaria</td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Rifat Morina</strong>
              </td>
              <td width="40%"> University of Prizren “Ukshin Hoti” Prizren</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Rita D’Ecclesia</strong>
              </td>
              <td width="40%"> Sapienza University of Rome</td>
              <td width="7%"> Italy</td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Sabiha Shala</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Selim Daku</strong>
              </td>
              <td width="40%"> University” Kadri Zeka” in Gjilan</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Suada Ajdarpasic</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Suada A. Džogović</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Tahir Arbneshi</strong>
              </td>
              <td width="40%"> University of Prishtina”Hasan Prishtina”</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Theranda Beqiri</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Vehbi Miftari</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Vehbi Ramaj</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Venera Llunji</strong>
              </td>
              <td width="40%"> AAB College</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Vjosa Hajdari Lleshi</strong>
              </td>
              <td width="40%"> University of “Haxhi Zeka” in Peja</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
            <tr>
              <td width="30%">
                <strong>Xhevdet Thaqi</strong>
              </td>
              <td width="40%"> University” Kadri Zeka” in Gjilan</td>
              <td width="7%"></td>
              <td width="20%"></td>
            </tr>
          </tbody>
        </table>
        <p className="mt-3">
          <strong>Technical Secretariat:</strong>
        </p>
        <div className="mx-2 technical-secretariat">
          <p>
            <strong>Adnan Lataj</strong>,
            <em>University of “Haxhi Zeka” in Peja</em>
          </p>
          <p>
            <strong>Diana Shala</strong>,
            <em>University of “Haxhi Zeka” in Peja</em>
          </p>
          <p>
            <strong>Armend Fetahaj</strong>,
            <em>University of “Haxhi Zeka” in Peja</em>
          </p>
          <p>
            <strong>Flamur Abazaj</strong>,
            <em>International Business College Mitrovica</em>
          </p>
          <p>
            <strong>Damir Gashi</strong>,
            <em>International Business College Mitrovica</em>
          </p>
          <p>
            <strong>Leutrim Edipi</strong>,
            <em>Institute for Research Education and Social Development</em>
          </p>
        </div>
      </div>
    </>
  );
};

export default ComitteesConference4;
