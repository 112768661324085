import React from "react";
import Navbar from "../Navbar/Navbar";
import MainJournalFilter from "../MainJournalFilter/MainJournalFilter";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ErrorPathing() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="mainWrapper">
      <Navbar />
      <MainJournalFilter />
      <main>
        <div
          className="mt-4 d-flex align-items-center justify-content-center px-2 mainContainer"
          style={{ flex: 1 }}
        >
          <div className="errorPage">
            <h1>404</h1>
            <h5>{t("pageNotFound")}</h5>
            <p>{t("pageCouldNotBeFound")}.</p>
            <button onClick={() => navigate("/")} className="btn">
              {t("goBackHome")}
            </button>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default ErrorPathing;
