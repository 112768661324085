import React from "react";

const ContactConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">Contact</h2>
      <div className="p-2 mt-3">
        <p className="mb-3">Email: conference.researchcult@unhz.eu</p>
        <p className="mb-3">Phone: +383 (0) 39 421 734</p>
        <p className="mb-3">Address: University of “Haxhi Zeka” in Peja</p>
        <p className="mb-3">Str. UÇK, nn, 30000, Peja, Kosova</p>
      </div>
    </>
  );
};

export default ContactConference4;
