import React from "react";

const VenueConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">Venue</h2>
      <div className="py-2 px-3 mt-3 venue">
        <p>
          <a href="https://unhz.eu/" target="_blank">
            Haxhi Zeka University
          </a>
        </p>
        <p>Str. UÇK, nn, 30000, Peja, Kosova</p>
        <p>&nbsp;</p>
        <p>
          <iframe
            style={{ border: "0" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8300.099908778868!2d20.293146086856424!3d42.65015540813531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xda20b8188c2a178d!2sHaxhi%20Zeka%20University%20in%20Peja!5e0!3m2!1sen!2s!4v1629451545329!5m2!1sen!2s"
            width="600"
            height="450"
            allowfullscreen="allowfullscreen"
          ></iframe>
        </p>
      </div>
    </>
  );
};

export default VenueConference4;
