import React, { useContext, useEffect, useState } from "react";
import { GetArticlesList } from "../../endpoints";
import { FaUserCircle } from "react-icons/fa";
import ArticleItem from "./ArticleItem";
import Preloader from "../../UI/Preloader/Preloader";
import { JournalContext } from "../../store/JournalContext";
import { Link } from "react-router-dom";
import { SearchContext } from "../../store/SearchContext";
import EmptyResponse from "../../UI/EmptyResponse";
import { useTranslation } from "react-i18next";

function ArticleList() {
  const [articles, setArticles] = useState([]);
  const [areArticlesLoaded, setAreArticlesLoaded] = useState(false);
  const { setNewPageNumber } = useContext(SearchContext);
  const { t } = useTranslation();

  useEffect(() => {
    GetArticlesList().then((res) => {
      setArticles(res.data.articles);
      setAreArticlesLoaded(true);
    });
  }, []);

  return (
    <div className="card mt-1">
      <h2 className="boardHeader">{t("latestArticles")}:</h2>
      <Preloader isReady={areArticlesLoaded}>
        {articles?.items?.length == 0 && <EmptyResponse />}
        {articles?.items?.length > 0 &&
          articles.items?.map((article, i) => {
            return <ArticleItem article={article} i={i} key={article.id} />;
          })}
        {articles.totalCount > 5 && (
          <Link
            to={"/search-articles"}
            className="innerDiv"
            onClick={() => {
              setNewPageNumber(1);
            }}
          >
            {t("showMore")} ({articles.totalCount - 5})
          </Link>
        )}
      </Preloader>
    </div>
  );
}

export default ArticleList;
