import React from "react";
import abstractBook from "../../../Assets/Files/Book-of-Abstracts_ICNA_STA_final.pdf";
import abstractBookImg from "../../../Assets/Images/Book-of-abstracts.jpg";

const AbstractBookConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">BOOK OF ABSTRACT</h2>
      <div className="py-2 px-3 mt-3">
        <a href={abstractBook} target="_blank">
          <img
            src={abstractBookImg}
            style={{ cursor: "pointer", width: "185px", objectFit: "cover" }}
            alt={"Book of Abstract"}
          />
        </a>
      </div>
    </>
  );
};

export default AbstractBookConference4;
