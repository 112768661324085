import React, { createContext, useEffect, useState } from "react";
import { GetAppSettingDetails, GetJournal } from "../endpoints";

const JournalContext = createContext({
  journalName: "",
  journalLongName: "",
  journalRGB: "",
  journalAbout: {},
  journalEditorialOffice: {},
  journalDescription: {},
  journalAuthorInstructions: {},
  journalIndexAndArchiving: {},
  journalReviewerInstructions: {},
  journalEditorInstructions: {},
  journalAims: {},
  journalSubjectAreas: {},
  appIsReady: false,
  setAppIsReady: () => {},
  journalData: [],
  journalLanguages: [],
  hasSections: false,
  hasTopics: false,
  hasSpecialIssues: false,
  showReviewers: false,
  showReviewersBoard: false,
  approveReviewByEditor: false,
  hasNews: false,
  hasVolumes: false,
  hasArticleTypes: false,
  showBanner: false,
  showBannerTitle: false,
  showEditors: false,
  showEditorsInChief: false,
  showCoEditorsInChief: false,
  showAssociateEditors: false,
  showTechnicalEditors: false,
  showSecretariatList: false,
});

function JournalContextProvider({ children }) {
  const [journalName, setJournalName] = useState("");
  const [journalLongName, setJournalLongName] = useState("");
  const [journalAbout, setJournalAbout] = useState("");
  const [journalEditorialOffice, setJournalEditorialOffice] = useState("");
  const [journalDescription, setJournalDescription] = useState("");
  const [journalAuthorInstructions, setJournalAuthorInstructions] =
    useState("");
  const [journalIndexAndArchiving, setJournalIndexAndArchiving] = useState("");
  const [journalSubjectAreas, setJournalSubjectAreas] = useState("");
  const [journalAims, setJournalAims] = useState("");
  const [appIsReady, setAppIsReady] = useState(false);
  const [journalEditorInstructions, setJournalEditorInstructions] =
    useState("");
  const [journalReviewerInstructions, setJournalReviewerInstructions] =
    useState("");
  const [journalLanguage, setJournalLanguage] = useState();
  const [hasSections, setHasSections] = useState(false);
  const [hasTopics, setHasTopics] = useState(false);
  const [hasSpecialIssues, setHasSpecialIssues] = useState(false);
  const [showReviewers, setShowReviewers] = useState(false);
  const [showReviewersBoard, setShowReviewersBoard] = useState(false);
  const [approveReviewByEditor, setApproveReviewByEditor] = useState(false);
  const [hasNews, setHasNews] = useState(false);
  const [hasVolumes, setHasVolumes] = useState(false);
  const [hasArticleTypes, setHasArticleTypes] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [showBannerTitle, setShowBannerTitle] = useState(false);
  const [showEditors, setShowEditors] = useState(false);
  const [showEditorsInChief, setShowEditorsInChief] = useState(false);
  const [showCoEditorsInChief, setShowCoEditorsInChief] = useState(false);
  const [showAssociateEditors, setShowAssociateEditors] = useState(false);
  const [showTechnicalEditors, setShowTechnicalEditors] = useState(false);
  const [showSecretariatList, setShowSecretariatList] = useState(false);

  useEffect(() => {
    GetAppSettingDetails()
      .then((res) => {
        setJournalName(res.data?.code.toUpperCase());
        setJournalLongName(res.data.name);
        const appSettings = res.data.appSettings;
        setJournalAbout(appSettings.find((field) => field.key === "About"));
        setJournalEditorialOffice(
          appSettings.find((field) => field.key === "EditorialOffice")
        );
        setJournalDescription(
          appSettings.find((field) => field.key === "Description")
        );
        setJournalAuthorInstructions(
          appSettings.find((field) => field.key === "Information for Authors")
        );
        setJournalIndexAndArchiving(
          appSettings.find((field) => field.key === "Indexing and Archiving")
        );
        setJournalSubjectAreas(
          appSettings.find((field) => field.key === "Subject Areas")
        );
        setJournalAims(
          appSettings.find((field) => field.key === "Aims & Scope")
        );
        setJournalEditorInstructions(
          appSettings.find((field) => field.key === "Information for Editors")
        );
        setJournalReviewerInstructions(
          appSettings.find((field) => field.key === "Information for Reviewers")
        );
        setHasSections(
          appSettings.find((field) => field.key === "Has Sections")
        );
        setHasTopics(appSettings.find((field) => field.key === "Has Topics"));
        setHasSpecialIssues(
          appSettings.find((field) => field.key === "Has Special Issues")
        );

        const langs = appSettings.find((field) => field.key === "Languages");

        setShowReviewers(
          appSettings.find((field) => field.key === "Show Reviewers")
        );
        setShowReviewersBoard(
          appSettings.find((field) => field.key === "Show Reviewers Board")
        );
        setHasNews(appSettings.find((field) => field.key === "Has News"));

        setHasVolumes(appSettings.find((field) => field.key === "Has Volumes"));

        setHasArticleTypes(
          appSettings.find((field) => field.key === "Has Article Types")
        );
        setShowBanner(appSettings.find((field) => field.key === "Show Banner"));
        setShowBannerTitle(
          appSettings.find((field) => field.key === "Show Banner Title")
        );
        setShowEditors(
          appSettings.find((field) => field.key === "Show Editors")?.isActive
        );
        setShowEditorsInChief(
          appSettings.find((field) => field.key === "Show Editors-in-Chief")
            ?.isActive
        );
        setShowCoEditorsInChief(
          appSettings.find((field) => field.key === "Show Co-Editors-in-Chief")
            ?.isActive
        );
        setShowAssociateEditors(
          appSettings.find((field) => field.key === "Show Associate Editors")
            ?.isActive
        );
        setShowTechnicalEditors(
          appSettings.find((field) => field.key === "Show Technical Editors")
            ?.isActive
        );
        setShowSecretariatList(
          appSettings.find((field) => field.key === "Show Secretariat List")
            ?.isActive
        );
        setJournalLanguage(langs.value);
        setAppIsReady(true);
      })
      .catch(() => {
        setAppIsReady(true);
      });
  }, []);
  return (
    <JournalContext.Provider
      value={{
        journalName: journalName,
        appIsReady: appIsReady,
        journalAbout: journalAbout,
        journalEditorialOffice: journalEditorialOffice,
        journalDescription: journalDescription,
        journalAuthorInstructions: journalAuthorInstructions,
        journalIndexAndArchiving: journalIndexAndArchiving,
        journalAims: journalAims,
        journalSubjectAreas: journalSubjectAreas,
        journalEditorInstructions: journalEditorInstructions,
        journalReviewerInstructions: journalReviewerInstructions,
        journalLanguages: journalLanguage,
        hasSections,
        hasTopics,
        hasSpecialIssues,
        showReviewers,
        showReviewersBoard,
        approveReviewByEditor,
        journalLongName,
        hasNews,
        hasVolumes,
        hasArticleTypes,
        showBanner,
        showBannerTitle,
        showEditors,
        showEditorsInChief,
        showCoEditorsInChief,
        showAssociateEditors,
        showTechnicalEditors,
        showSecretariatList,
      }}
    >
      {children}
    </JournalContext.Provider>
  );
}
export { JournalContext };
export default JournalContextProvider;
