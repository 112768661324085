import React, { useContext } from "react";
import Input from "../Inputs/Input/Input";
import "./Footer.css";
import Navbar from "../Navbar/Navbar";
import { journalLogo } from "../../JournalSettings";
import JournalActions from "../HomepageComponents/JournalActions";
import { useTranslation } from "react-i18next";
import { JournalContext } from "../../store/JournalContext";

const Footer = ({ showLogin = true }) => {
  const { t } = useTranslation();
  const { journalName } = useContext(JournalContext);

  return (
    <>
      <footer className="d-flex align-items-center">
        <div>
          <div className="logo d-flex align-items-center">
            {journalLogo && <img src={journalLogo}></img>}
            {journalName.toUpperCase()}
          </div>

          <p>
            {t("subscribeToReceiveNotification")} {journalName.toUpperCase()}
          </p>
          <Input
            type="email"
            id={"footerSubscribeEmail"}
            name={"footerSubscribeEmail"}
            placeholder={t("enterEmail")}
          ></Input>
          <button className="btn footerSubsricbeBtn">{t("subscribe")}</button>
        </div>
        <Navbar showLogo={false} showLogin={showLogin} showLanguages={false} />
      </footer>
      <div className="copyright d-flex flex-wrap justify-content-between align-items-center">
        © 2023 {journalName.toUpperCase()} {t("unlessOtherwiseStated")}
        <JournalActions />
      </div>
    </>
  );
};

export default Footer;
