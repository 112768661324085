import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetNews } from "../endpoints";
import Preloader from "../UI/Preloader/Preloader";
import { useNavigate } from "react-router-dom";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { JournalContext } from "../store/JournalContext";
import { NewsStatus } from "../Enums";

const News = () => {
  const { t } = useTranslation();
  const [areNewsReady, setAreNewsReady] = useState(false);
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const { hasNews } = useContext(JournalContext);

  useEffect(() => {
    GetNews(NewsStatus.Published)
      .then((res) => {
        setNews(res.data.news);
      })
      .finally(() => setAreNewsReady(true));
  }, []);

  return (
    <RouteInactiveChecker isActive={hasNews?.isActive}>
      <div className="card">
        <h2 className="boardHeader">{t("news")}</h2>

        <Preloader isReady={areNewsReady}>
          {news.map((el) => {
            return (
              <div
                key={el.id}
                className="newsWrapper innerDiv"
                onClick={() => navigate(`/news-details/${el.id}`)}
              >
                <span className="newsTitle">{el?.title}</span>
                <span
                  className="newsLimitedContent"
                  dangerouslySetInnerHTML={{ __html: el?.content }}
                ></span>
                <span>
                  <div>
                    <b>Published on: </b>{" "}
                    {(() => {
                      const dateObj = new Date(el?.createdOn);
                      if (!isNaN(dateObj)) {
                        const year = dateObj.getFullYear();
                        const month = (dateObj.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const day = dateObj
                          .getDate()
                          .toString()
                          .padStart(2, "0");
                        const formattedDate = `${day}/${month}/${year}`;
                        return <span>{formattedDate}</span>;
                      }
                      return null;
                    })()}
                  </div>
                </span>
              </div>
            );
          })}
        </Preloader>
      </div>
    </RouteInactiveChecker>
  );
};

export default News;
