import React, { useEffect, useState } from "react";
import { GetJournalTopicsList } from "../endpoints";
import Preloader from "../UI/Preloader/Preloader";
import { useTranslation } from "react-i18next";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { useContext } from "react";
import { JournalContext } from "../store/JournalContext";

function Topics() {
  const [topics, setTopics] = useState([]);
  const [areTopicsReady, setAreTopicsReady] = useState(false);
  const { t } = useTranslation();
  const {hasTopics} = useContext(JournalContext)

  useEffect(() => {
    GetJournalTopicsList().then((res) => {
      setTopics(res.data.sectionResearchTopics);
      setAreTopicsReady(true);
    });
  }, []);

  return (
    <RouteInactiveChecker isActive={hasTopics?.isActive}>
    <div className="card">
      <h2 className="boardHeader">{t("topics")}</h2>
      <Preloader isReady={areTopicsReady}>
        {topics?.map((topic) => {
          return (
            <div className="innerDiv topicContainer" key={topic.id}>
              <h2>{topic.name}</h2>
              <span>
                <b>{t("section")}: </b>
                <span className="text-muted">{topic.journalSection.name}</span>
              </span>
            </div>
          );
        })}
      </Preloader>
    </div>
    </RouteInactiveChecker>
  );
}

export default Topics;
