import React from "react";
import { useTranslation } from "react-i18next";

function BoardMemberItem({ user }) {
  const { t } = useTranslation();
  return (
    <div className="boardMemberContainer d-flex justify-content-between">
      <div>
        <div className="d-flex align-items-center header">
          <h2>
            {user.firstName} {user.middleName} {user.lastName}
          </h2>
          {user.website && (
            <a
              href={`https://${user.website}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Website
            </a>
          )}
        </div>
        <p
          className="d-flex align-items-start contact"
          style={{ fontWeight: "bold" }}
        >
          {" "}
          {user.title}
        </p>
        <span>
          {user.universityOrOrganization}
          {user?.department ? `, ${user?.department}` : ""}, {user.country.name}
          {user?.city ? `, ${user?.city}` : ""}, {user.zipCode}
        </span>
        <div className="d-flex align-items-start contact">
          {/* <span>{t("contact")}: </span> */}
          <p>{user.email}</p>
        </div>
        {user.interests && (
          <p>
            <span>{t("interests")}: </span>
            {user.interests}
          </p>
        )}
        {user.biography && (
          <p className="biography">
            <span>{t("biography")}: </span>
            {user.biography}
          </p>
        )}
      </div>
      {user.profilePhoto && (
        <img src={user.profilePhoto} alt={user.firstName + user.lastName} />
      )}
    </div>
  );
}

export default BoardMemberItem;
