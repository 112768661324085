import React from "react";
import UP from "../../../Assets/Images/6-up.png";
import IBC from "../../../Assets/Images/2-IBC_LOGO.jpg";
import SUR from "../../../Assets/Images/3-SUR.jpg";
import UZ from "../../../Assets/Images/4-UZ.jpg";
import UNWE from "../../../Assets/Images/5-UNWE.jpg";
import UPZ from "../../../Assets/Images/7-upz.png";
import UHZ from "../../../Assets/Images/1-UHZ.png";
import UFAGJ from "../../../Assets/Images/10-ugjk.png";
import UGJI from "../../../Assets/Images/9-ugji.png";
import UM from "../../../Assets/Images/8-um.png";
import UFZ from "../../../Assets/Images/11-ufz.png";
import IREDS from "../../../Assets/Images/12-ireds.jpg";
import MASHT from "../../../Assets/Images/13-masht.png";

const OrganizingPartnersConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">ORGANIZING PARTNERS</h2>
      <div className="py-2 mt-3 px-3 organizing-partners d-flex flex-column">
        <img
          style={{ margin: "13px auto 13px auto" }}
          src={UP}
          alt=""
          width="90"
          height="89"
        ></img>

        <img
          className="alignnone wp-image-1235 aligncenter"
          src={IBC}
          alt=""
          width="210"
          height="54"
          style={{ margin: "13px auto 13px auto" }}
        ></img>

        <img
          src={SUR}
          alt=""
          width="221"
          height="70"
          style={{ margin: "13px auto 13px auto" }}
        />
        <img
          src={UZ}
          alt=""
          width="188"
          height="126"
          style={{ margin: "13px auto 13px auto" }}
        />
        <img
          src={UNWE}
          alt=""
          width="114"
          height="110"
          style={{ margin: "13px auto 13px auto" }}
        />

        <img
          src={UPZ}
          alt=""
          width="119"
          height="119"
          style={{ margin: "13px auto 13px auto" }}
        />
        <img
          src={UHZ}
          alt=""
          width="113"
          height="113"
          style={{ margin: "13px auto 13px auto" }}
        />
        <img
          src={UFAGJ}
          alt=""
          width="185"
          height="160"
          style={{ margin: "13px auto 13px auto" }}
        />
        <img
          src={UGJI}
          alt=""
          width="173"
          height="139"
          style={{ margin: "13px auto 13px auto" }}
        />
        <img
          src={UM}
          alt=""
          width="119"
          height="119"
          style={{ margin: "13px auto 13px auto" }}
        />
        <img
          src={UFZ}
          alt=""
          width="189"
          height="136"
          style={{ margin: "13px auto 13px auto" }}
        />
        <img
          src={IREDS}
          alt=""
          width="173"
          height="76"
          style={{ margin: "13px auto 13px auto" }}
        />
        <img
          src={MASHT}
          alt=""
          width="137"
          height="154"
          style={{ margin: "13px auto 13px auto" }}
        />
      </div>
    </>
  );
};

export default OrganizingPartnersConference1;
