import React, { useContext, useState } from "react";
import { SearchContext } from "../../../store/SearchContext";

function Select({ id, name, options, defaultValue, triggerContext = false }) {
  const [value, setValue] = useState("");
  const { dispatchSearchParams } = useContext(SearchContext);

  function changeHandler(e) {
    setValue(e.target.value);
    if (!triggerContext) return;
    dispatchSearchParams({ type: "SELECT", input: e });
  }

  return (
    <select
      id={id}
      title={name}
      value={value}
      onChange={(e) => {
        changeHandler(e);
      }}
    >
      <option defaultValue={defaultValue} value="">
        {defaultValue}
      </option>
      {options?.map((el, i) => {
        return (
          <option key={el.id} value={el.id}>
            {el.name}
          </option>
        );
      })}
    </select>
  );
}

export default Select;
