import React, { useContext } from "react";
import { JournalContext } from "../store/JournalContext";
import RouteInactiveChecker from "../components/ErrorPathing/RouteInactiveChecker";
import { useTranslation } from "react-i18next";

function EditorialOffice() {
  const { journalEditorialOffice,journalName } = useContext(JournalContext);
  const { t } = useTranslation();

  return (
    <div className="card">
      <RouteInactiveChecker isActive={journalEditorialOffice?.isActive}>
        <h2 className="boardHeader">
          {journalName} {t("editorialOffice")}
        </h2>
        <div
          className="innerDiv"
          dangerouslySetInnerHTML={{ __html: journalEditorialOffice?.value }}
        ></div>
      </RouteInactiveChecker>
    </div>
  );
}

export default EditorialOffice;
