import React, { useContext, useState } from "react";
import "./Input.css";
import { SearchContext } from "../../../store/SearchContext";

function Input({
  type = "text",
  placeholder = "",
  id,
  label,
  name,
  triggerContext = false,
  defaultValue = false,
  dataPlaceholder = "",
  required = false,
  ariaRequired = false,
}) {
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const { dispatchSearchParams } = useContext(SearchContext);
  function changeHandler(e) {
    setValue(e.target.value);
    if (!triggerContext) return;
    dispatchSearchParams({ type: "INPUT", input: e });
  }
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        id={id}
        name={name}
        onChange={(e) => {
          changeHandler(e);
        }}
        value={value}
        data-placeholder={dataPlaceholder}
        required={required}
        aria-required={ariaRequired}
      ></input>
    </>
  );
}

export default Input;
