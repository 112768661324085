import React from "react";
import SurJournal from "../../../Assets/Images/SUR-journal.jpg";
import FamenaJournal from "../../../Assets/Images/FAMENA-journal.png";
import UnweJournal from "../../../Assets/Images/UNWE-journal.jpg";
import ManagementJournal from "../../../Assets/Images/Management-journal.jpg";
import UfagjJournal from "../../..//Assets/Images/UFAGJ-journal.jpg";
import PzJournal from "../../../Assets/Images/PZ-journal.jpg";

const SupportingJournalsConference4 = () => {
  return (
    <>
      <h2 className="boardHeader">SUPPORTING SCIENTIFIC JOURNALS </h2>
      <div className="p-2 mt-3">
        <p>
          The ICNA-STA Conference is supported by several journals listed below,
          where the presenting researchers may approach them accordingly for
          application to publish their research papers.
        </p>
        <p>
          Applicant researchers have to follow the journal&#8217;s editorial
          rules and criteria for application to publish their work.
        </p>
        <p>&nbsp;</p>
        <p>
          <a
            href="https://www.tandfonline.com/loi/cirs20"
            target="_blank"
            style={{ margin: " 0px 55px 42px 0" }}
          >
            <img src={SurJournal} alt="" width="168" height="242" />
          </a>
          <a
            href="https://famena.fsb.hr/"
            target="_blank"
            style={{ margin: " 0px 55px 42px 0" }}
          >
            <img src={FamenaJournal} alt="" width="163" height="242" />
          </a>
          <a
            href="https://www.unwe.bg/eajournal/en"
            target="_blank"
            style={{ margin: " 0px 55px 42px 0" }}
          >
            <img src={UnweJournal} alt="" width="170" height="239" />
          </a>
        </p>
        <p style={{ textAlign: "left" }}>
          <a
            href="https://www.efst.unist.hr/en/management"
            target="_blank"
            style={{
              padding: "0px",
              display: "block",
              margin: " 13px auto 13px auto",
              float: "none",
            }}
          >
            <img src={ManagementJournal} alt="" width="298" height="118" />
          </a>
        </p>
        <p style={{ textAlign: "left" }}>
          <a
            href="https://jseis.uni-gjk.org/index.php/jseis"
            target="_blank"
            style={{ margin: " 0px 55px 42px 0" }}
          >
            <img src={UfagjJournal} alt="" width="162" height="260" />
          </a>
          <a
            href="https://www.prizrenjournal.com/index.php/PSSJ"
            target="_blank"
            style={{ margin: " 0px 55px 42px 0" }}
          >
            <img src={PzJournal} width="163" height="259" />
          </a>
        </p>
      </div>
      {/* 
         
        </p> */}
    </>
  );
};

export default SupportingJournalsConference4;
