import React from "react";

const ScientificCommitteeConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">SCIENTIFIC COMMITTEE</h2>
      <p className="py-2 mt-3 px-3">
        <p className="pb-1">
          <strong>Names and Surname - Institution </strong>
        </p>
        <p className="pb-1">
          <span lang="SQ">Adnan Jashari - South East European University</span>
        </p>
        <p className="pb-1">
          Afrim Gjelaj - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">Agon Koka - University of Gjakova “Fehmi Agani”</p>
        <p className="pb-1">
          Albana Milaimi - University of Prizren “Ukshin Hoti”
        </p>
        <p className="pb-1">
          Albulena Blakaj - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          <span lang="SQ">Aleko Miho - University of Tirana </span>
        </p>
        <p className="pb-1">
          Alma Shehu Lokaj - University of Peja “Haxhi Zeka”
        </p>
        <p className="pb-1">
          Alush Musaj - University “Isa Boletini” in Mitrovica
        </p>
        <p className="pb-1">
          Antigona Hasani - University of Prishtina “Hasan Prishtina;
        </p>
        <p className="pb-1">
          Antigona Ukehaxhaj - University of Gjakova “Fehmi Agani”
        </p>
        <p className="pb-1">
          <span lang="SQ">Arbana Kadriu - South East European University</span>
        </p>
        <p className="pb-1">
          Arben Haziri - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Arbnor Pajaziti - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Ardrit Gashi - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Armand Krasniqi - University of Peja “Haxhi Zeka”
        </p>
        <p className="pb-1">
          Armend Shabani - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Armond Daci - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Arta Xhambazi - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Avdulla Alija - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Bardh Rugova - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Bardhok Bashota - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">Bardhyl Dauti - State University of Tetova</p>
        <p className="pb-1">
          Bashkim Nurboja - University of Peja “Haxhi Zeka”
        </p>
        <p className="pb-1">
          Behxhet Gaxhiqi - University of Gjakova “Fehmi Agani”
        </p>
        <p className="pb-1">
          Behxhet Mustafa - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Behxhet Shala - University “Isa Boletini” in Mitrovica
        </p>
        <p className="pb-1">
          Besim Gollopeni - University “Isa Boletini” in Mitrovica
        </p>
        <p className="pb-1">
          Besim Halilajdhe - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Besnik Krasniqi - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Bujar Pira - University of Applied Sciences Ferizaj
        </p>
        <p className="pb-1">
          Bujar Gallopeni - International Business College Mitrovica
        </p>
        <p className="pb-1">
          Burim Neziri - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Dafina Bytyqi - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Diana Kopeva - University of National and World Economy
        </p>
        <p className="pb-1">
          Driton Shabani - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Eda Vula - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">Elsa Vula - University of Gjakova “Fehmi Agani”</p>
        <p className="pb-1">
          Elvana Podvorica - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Ekrem Gjokaj - International Business College Mitrovica
        </p>
        <p className="pb-1">Emin Neziraj - University of Peja “Haxhi Zeka”</p>
        <p className="pb-1">Fadil Mamuti - State University of Tetova</p>
        <p className="pb-1">
          Fakije Zejnullahu - University of Applied Sciences Ferizaj
        </p>
        <p className="pb-1">
          Fatmir Mehmeti - University of Prizren “Ukshin Hoti”
        </p>
        <p className="pb-1">
          Faton Hoxha - University of Gjakova “Fehmi Agani”
        </p>
        <p className="pb-1">
          Ferdi Kamberi - University of Gjakova “Fehmi Agani”
        </p>
        <p className="pb-1">Fiorenza Deriu - Sapienza University of Rome</p>
        <p className="pb-1">Fisnik Morina - University of Peja “Haxhi Zeka”</p>
        <p className="pb-1">
          Flamur Salihu - University of Applied Sciences Ferizaj
        </p>
        <p className="pb-1">Florim Gallopeni - IREDS Institute</p>
        <p className="pb-1">
          Florina Jerliu - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">Florinda Imeri - State University of Tetova</p>
        <p className="pb-1">
          Gabrijela Velickovic - International Business College Mitrovica
        </p>
        <p className="pb-1">
          Gentiana Muhaxheri - University of Gjakova “Fehmi Agani”
        </p>
        <p className="pb-1">
          Hëna Maloku - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          <span lang="SQ">
            Hyrije Abazi Alili - South East European University
          </span>
        </p>
        <p className="pb-1">
          Ilir Gllareva - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Ilirjana Islami - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Jelena Djokic - International Business College Mitrovica
        </p>
        <p className="pb-1">
          Jelena Stanojevic - International Business College Mitrovica
        </p>
        <p className="pb-1">
          Jeton Shatri - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Juljan Kraja - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Kurtesh Sherifi - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Kushtrim Braha - University of Prizren “Ukshin Hoti”
        </p>
        <p className="pb-1">
          Laura Kusari - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">Lidija Ćurković - University of Zagreb</p>
        <p className="pb-1">
          Lulzim Millaku - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Lulzim Zeneli - University of Gjakova “Fehmi Agani”
        </p>
        <p className="pb-1">
          Majlinda Gjelaj - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Maria Peneva - University of National and World Economy
        </p>
        <p className="pb-1">
          Medina Braha - International Business College Mitrovica
        </p>
        <p className="pb-1">
          Melinda Mula - University of Gjakova “Fehmi Agani”
        </p>
        <p className="pb-1">
          Mentor Alishani - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Mersiha Kalac Kacamakovic - University of Peja “Haxhi Zeka”
        </p>
        <p className="pb-1">
          Mihone Kerolli Mustafa - International Business College Mitrovica
        </p>
        <p className="pb-1">
          Mimoza Hyseni Spahija - University of Peja “Haxhi Zeka”
        </p>
        <p className="pb-1">
          Naim Baftiu - University of Prizren “Ukshin Hoti”
        </p>
        <p className="pb-1">
          Naser Kabashi - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Nazli Tyfekci - University of Gjakova “Fehmi Agani”
        </p>
        <p className="pb-1">Nina Deliu - Sapienza University of Rome</p>
        <p className="pb-1">Pasquale Pavone - Pisa University of S. Anna</p>
        <p className="pb-1">
          Qamil Kabashi - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Qerkin Berisha - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">Raffaella Gallo - Sapienza University of Rome</p>
        <p className="pb-1">
          Rine Limani - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Romana Matanovac-Vuckovic -  University of Zagreb
        </p>
        <p className="pb-1">
          Rrahim Sejdiu - University of Applied Sciences Ferizaj
        </p>
        <p className="pb-1">
          Rrahman Paçarizi - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Rreze Gecaj - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">Sahmedin Sali - State University of Tetova</p>
        <p className="pb-1">
          Shefki Shterbani - University of Prizren “Ukshin Hoti”
        </p>
        <p className="pb-1">
          Sheruze Osmani-Ballazhi -  State University of Tetova
        </p>
        <p className="pb-1">
          Teuta Abrashi - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Teuta Jashari Kajtazi - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Valentin Toçi - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">Vehbi Miftari - University of Peja “Haxhi Zeka”</p>
        <p className="pb-1">
          <span lang="SQ">
            Veland Ramadani - South East European University
          </span>
        </p>
        <p className="pb-1">Vera Kopsaj - Sapienza University of Rome</p>
        <p className="pb-1">
          Visar Morina - University of Prishtina “Hasan Prishtina&#8221;
        </p>
        <p className="pb-1">
          Vjollca Dibra - University of Prizren “Ukshin Hoti”
        </p>
        <p className="pb-1">
          Vjollca Krasniqi - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Zamira Shatri - University of Gjakova “Fehmi Agani”
        </p>
        <p className="pb-1">
          Zana Limani - University of Prishtina “Hasan Prishtina
        </p>
        <p className="pb-1">
          Zeqir Hashani - University of Gjakova “Fehmi Agani”
        </p>
      </p>
    </>
  );
};

export default ScientificCommitteeConference1;
