import React, { useEffect, useState } from "react";
import { GetJournalUsers } from "../endpoints";
import BoardMemberItem from "../UI/BoardMemberItem";
import Preloader from "../UI/Preloader/Preloader";
import { useTranslation } from "react-i18next";

function ReviewerBoard({ userRole, isReviewerBoard, roleType }) {
  const [users, setUsers] = useState([]);
  const [areReviewersLoaded, setAreReviewersLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    GetJournalUsers(userRole, roleType).then((res) => {
      setUsers(res.data.users);
      setAreReviewersLoaded(true);
    });
  }, [userRole]);

  return (
    <div className="card boardContainer">
      <h2 className="boardHeader">
        {!isReviewerBoard
          ? users.length > 1
            ? t("reviewers")
            : t("reviewer")
          : t("reviewersBoard")}
      </h2>
      <div className="innerDiv">
        <Preloader isReady={areReviewersLoaded}>
          {users.map((user) => {
            return <BoardMemberItem user={user} key={user.id} />;
          })}
        </Preloader>
      </div>
    </div>
  );
}

export default ReviewerBoard;
