import React from "react";
import ICNASTA from "../../../Assets/Images/ICNA-STA-2013.png";

const AboutConference1 = () => {
  return (
    <>
      <h2 className="boardHeader">ABOUT THE CONFERENCE</h2>
      <div className="p-2 mt-3 ">
        <img
          style={{ width: "100%", objectFit: "contain" }}
          className="alignnone wp-image-1246"
          src={ICNASTA}
          alt=""
          height="350"
        />
        <div className="px-3">
          <p className="pb-3 mt-4">
            Within the framework of the project “
            <strong>
              Enhancing Research Culture in Higher Education in Kosovo
              (ResearchCult)”,
            </strong>
            supported by Erasmus +, with funds from the European Union, the
            University of Prishtina “Hasan Prishtina” is organizing the
            <strong>
              “International Conference on New Achievements in Science,
              Technology and Arts” - ICNA-STA”.
            </strong>
            The conference is co-organized with a number of local and
            international universities and higher education institutions. It
            intends to bring together the international and Kosovar academic
            staff, researchers, students, businesses and other local and
            international stakeholders aiming to share their scientific
            experiences, best practices and steer up cooperation in related
            fields.
          </p>
          <p className="pb-3">
            In addition, the conference attempts to create a friendly
            environment where multinational researchers community arrays from
            different fields can use interdisciplinary and innovative approaches
            to link the research with economic development. Through plenary
            sessions, oral and poster presentations, the participants will have
            the possibility to exchange opinions, introduce ideas and practical
            knowledge, which will be an opportunity, especially for young
            colleagues.
          </p>
          <p>
            On behalf of the Organizing and Scientific Committees of the
            Conference, it is our great pleasure to invite all professors,
            researchers and scholars to submit abstracts and attend the{" "}
            <strong>
              “International Conference on New Achievements in Science,
              Technology and Arts” - ICNA-STA”
            </strong>
            , which will take place from{" "}
            <span className="redColor">
              <strong>
                4<sup>th</sup> - 5<sup>th</sup> May 2023,{" "}
                <span style={{ color: "#000000" }}> in Prishtina, Kosova</span>
              </strong>
            </span>
          </p>
          <p className="mt-4">
            <strong>Format of the event: </strong>
            <p className="my-2">The two-day event will consist of:</p>
            <ul>
              <li>plenary sessions</li>
              <li>thematic sessions</li>
              <li>poster presentations</li>
            </ul>
          </p>
          <p className="mt-4 mb-2">
            The conference is anticipated{" "}
            <strong>to be held through the physical presence.</strong>
          </p>
          <p className="mb-3">
            <strong>The language of the conference will be English.</strong>
          </p>
          <div className="my-4">
            <h4 className="pb-2">
              <span className="headerColor">
                <strong>Important dates</strong>
              </span>
            </h4>
            <p className="pb-2">
              Call published:{" "}
              <span style={{ color: "#000000" }}>
                <strong>15/12/2022</strong>
              </span>
            </p>
            <p className="pb-2">
              Abstract submission:
              <strong>
                <span className="redColor">
                  15/03/2023 <em>(postponed)</em>
                </span>
              </strong>
            </p>
            <p className="pb-2">
              Notification of acceptance of abstract: within 15 days from the
              submission date
            </p>
            <p className="pb-2">
              Poster presentation submission:
              <span className="redColor">
                <strong> 15/03/2023</strong>
              </span>
            </p>
            <p>
              Final registration on the conference (for oral presentation and
              poster presentation):
              <span className="redColor">
                <strong>
                  {" "}
                  30/03/2023 <em>(postponed)</em>
                  <br />
                </strong>
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutConference1;
